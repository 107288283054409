import ViewAll from "../../styles/ViewAll";
import styled from "styled-components";
import RecipesLoading from "../recipes/RecipesLoading";
import useDatabaseRecipes from "../../hooks/useDatabaseRecipes";

function DashboardRecipes() {
  // const { fetchedRecipes } = useFetch("/complexSearch", 10);

  const {fetchedRecipes} = useDatabaseRecipes("gptRecipes", 8)
  return (
    <StyledRecipe>
      <div className="recipes">
        <div className="title">
          <h4>Explore</h4>
          <ViewAll navigate={"explore"} />
        </div>
        <div className="content">
          <RecipesLoading data={fetchedRecipes} />
        </div>
      </div>
    </StyledRecipe>
  );
}

const StyledRecipe = styled.div`
  background-color: var(--secondary-color);
  margin: var(--section-margin) auto;
  padding: 20px 0 20px 0;
  h4{
    color: white;
  }
  p,
  li,
  span,
  h5 {
    color: var(--text-color);
  }
  a {
    color: white;
  }
  li {
    list-style: inside;
    color: white;
  }
  .recipes {
    width: 90%;
    margin: auto;
    .card-recipes {
      border: none;
      box-shadow: none;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      margin-top: 40px;
      .card-data{
        border: none !important;
        box-shadow: none !important;
      }
      .column {
        display: flex;
        gap: 20px;
        p {
          margin: 6px auto;
        }
        .img {
          background-color: white;
          width: 440px;
          min-height: 250px;
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0px 2px 4px var(--text-light-color);
          img {
            max-width: 90%;
          }
        }
      }
    }
  }
`;

export default DashboardRecipes;
