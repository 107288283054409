import axios from "axios";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import imageCompression from "browser-image-compression";

// Stability.ai
const engineId = "stable-diffusion-xl-1024-v1-0";
const apiHost = "https://api.stability.ai";
const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

export default function useGenerateImg() {
  function convertBase64ToBlob(base64Data) {
    // Split the base64 string at the comma (`,`) to separate data and mime-type

    // Decode the base64 data
    const decodedData = atob(base64Data);

    // Convert the decoded data to a Uint8Array
    const buffer = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      buffer[i] = decodedData.charCodeAt(i);
    }

    return new Blob([buffer], { type: "image/png" });
  }

  async function compressImageHandler(imageFile) {
    const options = {
      maxSizeMB: 1,
      // maxWidthOrHeight: 320,
      useWebWorker: true,
      initialQuality: 0.7,
      fileType: "image/webp",
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  return async function imageGeneration(
    recipeName,
    recipeIngredients,
    steps,
    recipeId
  ) {
    recipeIngredients = recipeIngredients.map(
      (ingredient) => ingredient.nameClean
    );
    try {
      const response = await axios.post(
        `${apiHost}/v1/generation/${engineId}/text-to-image`,
        // `https://api.stability.ai/v2beta/stable-image/generate/core`,
        {
          text_prompts: [
            {
              text: `image of the following recipe: ${recipeName}, which is made from following ingredients: ${recipeIngredients} and has following cooking instructions: ${steps}`,
            },
          ],
          // prompt: `image of the following recipe: ${recipeName}, which is made from following ingredients: ${recipeIngredients}`,
          style_preset: "photographic",
          cfg_scale: 17,
          // width: 320,
          // height: 231,
          width: 1216,
          height: 832,
          // aspect_ratio: "3:2",
          steps: 25,
          samples: 1,
          // output_format: "webp",
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const storage = getStorage();
      const imageRef = ref(storage, `gptRecipeImages/${recipeId}`);

      let blob = convertBase64ToBlob(response.data.artifacts[0].base64);

      let compressedImage = await compressImageHandler(blob);

      // await uploadString(imageRef, response.data.image, "base64", {
      //   contentType: "image/webp",
      // });
      await uploadBytes(imageRef, compressedImage);
      const url = await getDownloadURL(imageRef);
      console.log("url", url);
      return url;
    } catch (e) {
      console.log("image generation error", e);
    }
  };
}
