import styled from "styled-components";

import RecipesLoading from "../components/recipes/RecipesLoading";
import SearchableTabs from "../components/SearchableTabs";
import { useEffect, useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import LoadingCircle from "../styles/LoadingCircle";
// import useDatabaseRecipes from "../hooks/useDatabaseRecipes";
import { searchAlgolia } from "../utils/algoliaFunctions";
import { useLocation } from "react-router";

let initailSearchInput = [];
export default function ExploreRecipes() {
  // const { fetchedRecipes, setFetchedRecipes, fetchRecipes } =
  //   useDatabaseRecipes("gptRecipes", 25);
  const [fetchedRecipes, setFetchedRecipes] = useState({
    data: [],
    loading: true,
  });
  const [infiniteScroll, setInfiniteScroll] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let inputQueryParam = queryParams.get("initial-search-input")

  const [inputs, setInputs] = useState({
    cuisines: [],
    maxReadyTime: 10000,
    query: inputQueryParam?.split(",") || initailSearchInput,
    dietCategory: [],
  });
  const observer = useRef();

  const lastRecipeElementRef = useCallback(
    (node) => {
      try {
        if (fetchedRecipes.loading || !infiniteScroll) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(async (entries) => {
          // if (entries[0].isIntersecting) {
          //   fetchRecipes({
          //     query: inputs.query.join(" "),
          //     cuisine: inputs.cuisine.join(","),
          //     maxReadyTime: inputs.maxReadyTime,
          //     number: 25,
          //     combineResults: true,
          //   }).then((recipes) => recipes.length < 25 && setInfiniteScroll(false));
          // }
          if (entries[0].isIntersecting) {
            // if (inputs.query.length === 0) {
            //   fetchRecipes({
            //     cuisine: inputs.cuisine,
            //     combineResults: true,
            //     number: 25,
            //   }).then(
            //     (fetchedRecipes) =>
            //       fetchedRecipes?.length < 25 && setInfiniteScroll(false)
            //   );
            // } else {
            searchAlgolia({
              query: inputs.query.join(" "),
              cuisines: inputs.cuisines,
              maxReadyTime: inputs.maxReadyTime,
              fetchNextPage: true,
              dietCategory: inputs.dietCategory,
            }).then((fetchedRecipes) => {
              setFetchedRecipes((current) => ({
                ...current,
                data: [...current.data, ...fetchedRecipes],
              }));
              fetchedRecipes?.length < 25 && setInfiniteScroll(false);
            });
            // }
          }
        });
        if (node) observer.current.observe(node);
      } catch (e) {
        console.log("error in intersection observer", e);
      }
    },
    [fetchedRecipes.loading, infiniteScroll]
  );

  async function searchableTabsHandler(combineResults) {
    try {
      setFetchedRecipes({ data: [], loading: true });

      // spoonacular
      // await fetchRecipes({
      //   query: inputs.query.join(" "),
      //   cuisine: inputs.cuisine.join(","),
      //   maxReadyTime: inputs.maxReadyTime || 1000,
      //   number: 25,
      //   combineResults,
      // });
      // database algolia recipes
      // if (inputs.query.length > 0) {
      const hits = await searchAlgolia({
        query: inputs.query.join(" "),
        cuisines: inputs.cuisines,
        maxReadyTime: inputs.maxReadyTime,
        dietCategory: inputs.dietCategory,
      });
      if (hits?.length === 25 && infiniteScroll === false) {
        setInfiniteScroll(true);
      } else if (hits?.length < 25 && infiniteScroll === true) {
        setInfiniteScroll(false);
      }
      setFetchedRecipes({
        data: hits,
        loading: false,
      });
      // } else {
      //   await fetchRecipes({ ...inputs, combineResults });
      // }
    } catch (e) {
      setFetchedRecipes((current) => ({ ...current, loading: false }));
      setInfiniteScroll(false);
      console.log("error while searching or filtering recipes", e);
    }
  }

  useEffect(() => {
    initailSearchInput = inputs.query;
  }, [inputs.query]);

  return (
    <StyledExploreRecipes>
      <SearchableTabs
        searchableTabsHandler={searchableTabsHandler}
        // tabs={[
        //   {
        //     name: "Asian",
        //     value: "korean, chinese, thai, vietnamese, japanese",
        //   },
        //   { name: "Mediterranean", value: "greek, mediterranean" },
        //   { name: "Italian", value: "italian" },
        //   { name: "French", value: "french" },
        //   { name: "Indian", value: "indian" },
        //   { name: "Southern", value: "cajun, southern" },
        //   {
        //     name: "Other European",
        //     value:
        //       "german, nordic, british, spanish, easter european, irish, european",
        //   },
        //   { name: "Mexican", value: "mexican" },
        //   { name: "Kosher", value: "jewish" },
        //   { name: "Middle Eastern", value: "middle eastern" },
        //   { name: "15min", value: 15 },
        //   { name: "30min", value: 30 },
        //   { name: "45min", value: 45 },
        // ]}
        tabs={[
          {
            name: "Asian",
            value: "Korean,Chinese,Thai,Vietnamese,Japanese",
          },
          { name: "Mediterranean", value: "Greek,Mediterranean" },
          { name: "Italian", value: "Italian" },
          { name: "French", value: "French" },
          { name: "Indian", value: "Indian" },
          { name: "Southern", value: "Cajun,Southern" },
          {
            name: "Other European",
            value:
              "German,Nordic,British,Spanish,Easter European,Irish,European",
          },
          { name: "Mexican", value: "Mexican" },
          { name: "Kosher", value: "Eastern European" },
          { name: "Middle Eastern", value: "Middle Eastern" },
          { name: "15min", value: 15 },
          { name: "30min", value: 30 },
          { name: "45min", value: 45 },
        ]}
        dietCategory={[
          { name: "Gluten Free", value: "Gluten Free" },
          { name: "Ketogenic", value: "Ketogenic" },
          { name: "Vegetarian", value: "Vegetarian" },
          { name: "Dairy Free", value: "Dairy Free" },
          { name: "Low Fat", value: "Low Fat" },
          { name: "Vegan", value: "Vegan" },
          { name: "Pescetarian", value: "Pescetarian" },
          { name: "Paleo", value: "Paleo" },
          { name: "Low FODMAP", value: "Low FODMAP" },
          { name: "Whole30", value: "Whole30" },
        ]}
        inputs={inputs}
        setInputs={setInputs}
      />
      <RecipesLoading
        lastElementRef={lastRecipeElementRef}
        data={fetchedRecipes}
        title=""
      />
      {infiniteScroll && <LoadingCircle className="loading-circle" />}
    </StyledExploreRecipes>
  );
}

const StyledExploreRecipes = styled.div`
  .foods {
    margin-top: var(--section-margin);
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      width: 80%;
      margin: auto;
      h1 {
        max-width: 15ch;
      }
      p {
        max-width: 42ch;
      }
    }
    .foods-selection {
      display: grid;
      grid-template-columns: 0.25fr 0.75fr;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      margin: 20px 0 20px auto;
      column-gap: 20px;
      .tabs {
        flex-basis: 25%;
        .tab {
          display: flex;
          align-items: center;
          /* justify-content: flex-start; */
          gap: 20px;
          width: 65%;
          padding: 3px 6px;
          border-radius: 50px;
          margin-bottom: 4px;
          cursor: pointer;
          &:hover {
            background-color: var(--accent-color);
            h5 {
              color: white;
            }
          }
          img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: white;
          }
          h5 {
            color: var(--text-color);
          }
        }
        .active {
          background-color: var(--accent-dark-color);
          h5 {
            color: white;
          }
        }
      }
      .items {
        display: flex;
        position: relative;
        flex-basis: 75%;
        width: 57vw;
      }
      button {
        position: absolute;
        top: -20%;
        color: var(--text-light-color);
        padding: 4px 2px;
        min-width: 30px;
        background-color: transparent;
      }
      .left-btn {
        right: 15%;
      }
      .right-btn {
        right: 10%;
      }
    }
  }
  .recipes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--section-margin);
    .web,
    .manual {
      padding: 60px;
      flex-basis: 50%;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .items {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .manual {
      background-color: var(--secondary-color);
      a,
      h2 {
        color: white;
      }
    }
  }
  .loading-circle {
    margin-bottom: 15vh;
  }
`;
