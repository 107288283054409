import React, { useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import styled from "styled-components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import demoImg from "../../assets/demoImg.png";
import imageUpload from "../../utils/uploadImg";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../LoadingButton";
import { alertActions } from "../../store/alertSlice";
import { userRecipesActions } from "../../store/userRecipesSlice";
import CloseIcon from "@mui/icons-material/Close";

const RecipeForm = ({ recipeType, handleBackdrop, data }) => {
  const extendedIngredients = [
    ...(data?.extendedIngredients || [
      { nameClean: "", amount: "", unit: "unit" },
    ]),
  ];
  const steps = [...(data?.steps || [""])];

  const uid = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();
  const [recipeImg, setRecipeImg] = useState({
    name: "",
    data: data?.image || "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: data?.title || "",
    servings: data?.servings || "",
    readyInMinutes: data?.readyInMinutes || "",
    dishType: data?.dishType || "breakfast",
    summary: data?.summary || "",
    extendedIngredients: extendedIngredients || [
      { nameClean: "", amount: "", unit: "unit" },
    ],
    steps: steps || [""],
    image: data?.image || "",
    recipeType,
    id: data?.id || "",
  });

  async function submitHandler(e) {
    e.preventDefault();
    console.log("form", formData);
    setLoading(true);
    const id = Math.random().toString(16).slice(2);
    try {
      const imageUrl =
        recipeImg.data && (await imageUpload(recipeImg, "userRecipes"));
      const newRecipe = {
        image: imageUrl,
        ...formData,
        uid,
        recipeType,
      };
      await setDoc(doc(db, `${recipeType}Recipes`, id), {
        ...newRecipe,
        id,
      });
      recipeType === "web"
        ? dispatch(
            userRecipesActions.appendUserRecipes({
              data: { ...newRecipe, id },
              recipeType: "web",
            })
          )
        : dispatch(
            userRecipesActions.appendUserRecipes({
              data: { ...newRecipe, id },
              recipeType: "manual",
            })
          );
      dispatch(
        alertActions.setAlert({
          title: "Recipe added successfully",
          messageType: "success",
        })
      );
      handleBackdrop(e);
    } catch (error) {
      console.log("error", error);
      dispatch(
        alertActions.setAlert({
          title: "Failed to add recipe",
          messageType: "error",
        })
      );
    }
    setLoading(false);
  }

  const updateRecipeHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("form", formData);
    try {
      await updateDoc(doc(db, `${data.recipeType}Recipes`, data.id), formData);
      recipeType === "web"
        ? dispatch(
            userRecipesActions.updateUserRecipe({
              data: formData,
              id: data.id,
              image: recipeImg.data,
              recipeType: "web",
            })
          )
        : dispatch(
            userRecipesActions.updateUserRecipe({
              data: formData,
              id: data.id,
              image: recipeImg.data,
              recipeType: "manual",
            })
          );
      dispatch(
        alertActions.setAlert({
          title: "Recipe updated successfully",
          messageType: "success",
        })
      );
      handleBackdrop(e);
    } catch (error) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to update recipe",
          messageType: "error",
        })
      );
      console.log("error", error);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function handleSteps(event, index) {
    setFormData((current) => {
      let items = current.steps;
      items[index] = event.target.value;
      return { ...current, steps: items };
    });
  }
  function handleIngredients(event, index) {
    console.log(event.target.name, event.target.value, index);
    setFormData((prevFormData) => {
      let items = prevFormData.extendedIngredients;
      items[index] = {
        ...items[index],
        [event.target.name]: event.target.value,
      };
      return {
        ...prevFormData,
        extendedIngredients: items,
      };
    });
  }
  const fileSelectedHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setRecipeImg({ name: e.target.files[0].name, data: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleAddItem = (e, type, index) => {
    e.preventDefault();
    setFormData((prev) =>
      type === "ingredient"
        ? {
            ...prev,
            extendedIngredients: [
              ...prev.extendedIngredients,
              {
                nameClean: "",
                amount: "",
                unit: "unit",
              },
            ],
          }
        : {
            ...prev,
            steps: [...prev.steps, ""],
          }
    );
  };
  const handleRemoveItem = (e, type, index) => {
    e.preventDefault();
    console.log("index", index);
    setFormData((prev) =>
      type === "ingredient"
        ? {
            ...prev,
            extendedIngredients: prev.extendedIngredients
              .slice(0, index)
              .concat(
                prev.extendedIngredients.slice(
                  index + 1,
                  prev.extendedIngredients.length
                )
              ),
          }
        : {
            ...prev,
            steps: prev.steps
              .slice(0, index)
              .concat(prev.steps.slice(index + 1, prev.steps.length)),
          }
    );
  };

  return (
    <StyledForm>
    <div className="heading">
        <h4>{recipeType === "web" ? "Add Web Recipe" : "Add Manual Recipe"}</h4>
        <button className="icon" onClick={(e) => handleBackdrop(false)}>
          {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
        </button>
      </div>
      <div className="form-div">
        <form onSubmit={submitHandler}>
          <label className="image-upload">
            <span>Upload a image</span>
            <div className="input">
              <input
                type="file"
                name="imageUrl"
                onChange={fileSelectedHandler}
              />
              <img
                src={recipeImg?.data !== "" ? recipeImg.data : demoImg}
                alt=""
              />
            </div>
          </label>
          <label className="title">
            <span>Name</span>
            <input
              type="text"
              name="title"
              placeholder="Recipe Name"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </label>
          {recipeType === "web" && (
            <label className="url">
              <span>Url</span>
              <input
                type="text"
                name="recipeUrl"
                placeholder="Url"
                value={formData.recipeUrl}
                onChange={handleChange}
              />
            </label>
          )}
          <label className="steps">
            <span>Steps</span>
            {formData.steps.map((step, index) => (
              <div key={index}>
                <input
                  type="text"
                  name={`step${index + 1}`}
                  value={formData.steps[index]}
                  onChange={(e) => handleSteps(e, index)}
                  placeholder={`Step ${index + 1}`}
                />
                {formData.steps.length > 1 &&
                  index !== formData.steps.length - 1 && (
                    <button
                      type="button"
                      onClick={(e) => handleRemoveItem(e, "step", index)}
                    >
                      <RemoveCircleOutlineIcon
                        color="warning"
                        fontSize="medium"
                      />
                    </button>
                  )}
                {index === formData.steps.length - 1 && (
                  <button
                    type="button"
                    onClick={(e) => handleAddItem(e, "step")}
                  >
                    <AddCircleOutlineIcon color="success" fontSize="medium" />
                  </button>
                )}
                <br />
              </div>
            ))}
          </label>
          <label className="summary">
            <span>Description</span>
            <textarea
              name="summary"
              placeholder="Description"
              value={formData.summary}
              onChange={handleChange}
            />
          </label>
          <div className="details">
            <span>Details</span>
            <label>
              <p>
                <GroupIcon fontSize="medium" />
              </p>
              <input
                type="number"
                name="servings"
                placeholder="Servings"
                value={formData.servings}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              <p>
                <AvTimerIcon fontSize="medium" />
              </p>
              <input
                type="number"
                name="readyInMinutes"
                placeholder="Cooking Time(min)"
                value={formData.readyInMinutes}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              <p>
                <FastfoodIcon fontSize="medium" />
              </p>
              <select
                name="dishType"
                defaultValue={formData.dishType}
                onChange={handleChange}
              >
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
                <option value="Snack">Snack</option>
              </select>
            </label>
          </div>

          <label className="ingredients">
            <span>Ingredients</span>
            {formData.extendedIngredients.map((ingredient, index) => (
              <div key={index}>
                <input
                  type="text"
                  name={`nameClean`}
                  value={formData.extendedIngredients[index].nameClean}
                  onChange={(e) => handleIngredients(e, index)}
                  placeholder={`Ingredient ${index + 1}`}
                  required
                />
                <input
                  type="number"
                  min="0.01"
                  step="0.01"
                  name="amount"
                  className="amount"
                  defaultChecked={1}
                  value={formData.extendedIngredients[index].amount}
                  onChange={(e) => handleIngredients(e, index)}
                  placeholder={`Amount`}
                  required
                />
                <select
                  name="unit"
                  defaultValue="unit"
                  value={formData.extendedIngredients[index].unit}
                  onChange={(e) => handleIngredients(e, index)}
                >
                  <optgroup>
                    <option value="unit">Unit</option>
                    <option value="kg">Kg</option>
                    <option value="lbs">Lbs</option>
                    <option value="oz">Oz</option>
                    <option value="liter">Liter</option>
                    <option value="cup">Cup</option>
                    <option value="teaspoon">Teaspoon</option>
                    <option value="tablespoon">Tablespoon</option>
                  </optgroup>
                </select>
                {formData.extendedIngredients.length > 1 &&
                  index !== formData.extendedIngredients.length - 1 && (
                    <button
                      type="button"
                      onClick={(e) => handleRemoveItem(e, "ingredient", index)}
                    >
                      <RemoveCircleOutlineIcon
                        color="warning"
                        fontSize="medium"
                      />
                    </button>
                  )}
                {index === formData.extendedIngredients.length - 1 && (
                  <button
                    type="button"
                    onClick={(e) => handleAddItem(e, "ingredient", index)}
                  >
                    <AddCircleOutlineIcon color="success" fontSize="medium" />
                  </button>
                )}
              </div>
            ))}
          </label>
          {data ? (
            <LoadingButton
              loading={loading}
              title={"Update Recipe"}
              onClick={updateRecipeHandler}
              className="submit-btn"
            />
          ) : (
            <LoadingButton
              loading={loading}
              title="Add Recipe"
              className="submit-btn"
              type="submit"
            />
          )}
        </form>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled.div`
  background-color: white;
  border-radius: 24px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .form-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 10px;
      .title,
      .url,
      .steps,
      .summary {
        grid-column: 1/2;
      }
      .details {
        grid-row: 1/2;
        grid-column: 2/3;
      }
      .ingredients {
        grid-row: 2/6;
        grid-column: 2/3;
        .amount {
          width: 80px;
          padding: 0 4px;
        }
        select {
          width: 60px;
          height: 34px;
          padding: 0 4px;
        }
      }
      .submit-btn {
        grid-column: 1/3;
        margin: auto;
      }
      label {
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: 4px;
        }
        input {
          height: 34px;
          width: 180px;
        }
      }
      .image-upload {
        .input {
          position: relative;
          input {
            width: 100px;
            height: 100px;
            color: white;
            background-color: white;
            opacity: 0;
          }
          img {
            position: absolute;
            top: 5%;
            left: 5%;
            width: 120px;
            cursor: pointer;
            border-radius: var(--radius);
          }
        }
      }
      .ingredients,
      .steps {
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 4px 0;
          button {
            background-color: transparent;
            min-width: 20px;
          }
        }
      }
      .details {
        label {
          flex-direction: row;
          align-items: center;
          gap: 10px;
          input {
            width: 180px;
          }
          select {
            height: 36px;
            width: 180px;
          }
        }
      }
      .summary {
        textarea {
          min-width: 240px;
          max-width: 400px;
          min-height: 80px;
          max-height: 400px;
          border-radius: 12px;
          padding: 10px;
        }
      }
    }
  }
  @media (max-width: 650px) {
    .form-div {
      form {
        grid-template-columns: 1fr;
        .title,
        .url,
        .steps,
        .summary,
        .details,
        .ingredients,
        .steps,
        .image-upload {
          grid-column: 1/2;
          grid-row: auto;
        }
      }
      .ingredients {
        div {
          /* justify-content: space-between; */
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export default RecipeForm;
