import React from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const TermsOfService = () => {
  return (
    <>
      <Nav />
      <TermsContainer>
        <h1>BREAK BREAD INC. TERMS OF SERVICE</h1>
        <p>Effective Date: September 5, 2024</p>
        <h2>1. Introduction</h2>
        <p>
          1.1 Welcome to Break Bread Inc. ("Company", "we", "us", or "our"). Our
          website and services are provided subject to the following Terms of
          Service ("Terms").
        </p>
        <h2>2. Acceptance</h2>
        <p>
          2.1 By accessing or using our website or services, you ("User", "you",
          or "your") agree to be bound by these Terms.
        </p>
        <h2>3. Services</h2>
        <p>
          3.1 We provide a platform for recipe suggestion and meal planning
          where users can search recipes and add to their meal plan with a
          built-in calendar and shop the ingredients. It also has ai recipe
          generation, nutrition stats and more.
        </p>
        <h2>4. User Conduct</h2>
        <p>
          4.1 You agree to:
          <ul>
            <li>Use our services for lawful purposes only.</li>
            <li>
              Not post or transmit any harmful, threatening, or harassing
              content.
            </li>
            <li>
              Not infringe on intellectual property rights or privacy rights.
            </li>
            <li>Comply with applicable laws and regulations.</li>
          </ul>
        </p>
        <h2>5. Intellectual Property</h2>
        <p>
          5.1 All content and materials on our website are owned by us or our
          licensors.
        </p>
        <h2>6. Termination</h2>
        <p>
          6.1 We may terminate or suspend your access to our services at any
          time, without notice, for any reason.
        </p>
        <h2>7. Limitation of Liability</h2>
        <p>
          7.1 In no event shall we be liable for any damages, including
          consequential, incidental, or punitive damages.
        </p>
        <h2>8. Governing Law</h2>
        <p>
          8.1 These Terms shall be governed by and construed in accordance with
          the laws of United States of America.
        </p>
        <h2>9. Changes to Terms</h2>
        <p>
          9.1 We reserve the right to modify these Terms at any time, without
          notice.
        </p>
        <h2>10. Nutrition Information Disclaimer</h2>
        <p>
          10.1 The recipes, meal planning, and nutritional tracking features provided
          by our application are intended for informational and educational
          purposes only. While we strive to offer accurate and up-to-date
          information, we are not licensed nutritionists, dietitians, or
          healthcare professionals. The nutritional content of recipes is based
          on available data and may not be completely accurate or applicable to
          individual dietary needs. This app should not be considered a
          substitute for professional medical or nutritional advice. Users are
          encouraged to consult with a doctor, dietitian, or other qualified
          health professional before making any changes to their diet or relying
          on the information provided by this application. By using this app,
          you agree that we are not responsible or liable for any inaccuracies,
          omissions, or health-related consequences that may arise.
        </p>
        <h2>11. Contact Us</h2>
        <p>
          11.1 If you have any questions or concerns, please contact us at
          steven@familyfoodandhealth.com
        </p>
        <p>
          By using our services, you acknowledge that you have read, understood,
          and agree to be bound by these Terms.
        </p>
      </TermsContainer>
      <Footer />
    </>
  );
};

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 10px;
  }
`;

export default TermsOfService;
