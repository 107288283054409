import React, { useState } from "react";
import LoadingButton from "../LoadingButton";
import { alertActions } from "../../store/alertSlice";
import { useDispatch } from "react-redux";
import InstacartLogo from "../../assets/Instacart_Carrot.png";
import { instacartApi } from "../../utils/apis";
import styled from "styled-components";

export default function InstacartIngredientBtn({
  selectedItems,
  ingredientMeasures,
}) {
  const [instacartLink, setInstacartLink] = useState({
    link: "",
    loading: false,
    error: "",
  });
  const dispatch = useDispatch();

  async function fetchInstacartShoppingLink() {
    setInstacartLink({ link: "", loading: true, error: "" });
    if (selectedItems.length === 0) {
      dispatch(
        alertActions.setAlert({
          title: "No cart item selected",
          messageType: "error",
        })
      );
      setInstacartLink({
        link: "",
        loading: false,
        error: "Please select at least one cart item.",
      });
      return;
    }
    const shoppingItems = {
      title: "BreakBread Shopping List",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/break-bread-prod.appspot.com/o/web-app-assets%2FBB%20logo%20name-01.png?alt=media&token=54d9f1f2-4a18-473c-beb2-fb35dd3053bb",
      link_type: "shopping_list",
      line_items: selectedItems?.map((ingredient) => ({
        name: ingredient.nameClean,
        display_text:
          ingredient.nameClean?.charAt(0).toUpperCase() +
          ingredient.nameClean?.slice(1),
        quantity: ingredient?.measures
          ? ingredient?.measures[ingredientMeasures].amount
          : ingredient.amount,
        unit: ingredient?.measures
          ? ingredient?.measures?.[ingredientMeasures].unitLong
          : ingredient.unit,
        line_item_measurements: [
          {
            quantity: ingredient?.measures
              ? ingredient?.measures?.[ingredientMeasures].amount
              : ingredient.amount,
            unit: ingredient?.measures
              ? ingredient?.measures?.[ingredientMeasures].unitLong
              : ingredient.unit,
          },
        ],
      })),
      landing_page_configuration: {
        partner_linkback_url: "string",
        enable_pantry_items: true,
      },
    };
    console.log("recipe info", shoppingItems);
    try {
      const res = await instacartApi.post("/products_link", shoppingItems);
      // window.location.target = "_blank";
      // window.location.href = res.data.products_link_url;
      window.open(res.data.products_link_url, "_blank");
      setInstacartLink({
        link: res.data.products_link_url,
        loading: false,
        error: "",
      });
    } catch (e) {
      console.log("error while fetching instacart recipe link", e);
      setInstacartLink((current) => ({
        ...current,
        loading: false,
        error: "Shopping cart not working",
      }));
    }
  }

  return (
    <StyledInstaBtn>
      <LoadingButton
        loading={instacartLink.loading}
        title={
          <>
            <img src={InstacartLogo} alt="" /> Confirm Cart
          </>
        }
        onClick={fetchInstacartShoppingLink}
        className="instacart-btn"
      />
    </StyledInstaBtn>
  );
}

const StyledInstaBtn = styled.div`
  .instacart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #108910;
    color: white;
    img {
      width: 20px;
      height: auto;
    }
    &:hover {
      background-color: #0d760d;
    }
  }
`;
