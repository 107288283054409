import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Calendar from "../components/meal-planning/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { infoAlertActions } from "../store/infoAlertSlice";
import moment from "moment";
import TitleOnlyRecipes from "../components/recipes/TitleOnlyRecipes";
import useAddStackedRecipe from "../hooks/useAddStackedRecipe";

function MealPlanning() {
  
  const dispatch = useDispatch();
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const meals = useSelector(
    (state) => state.mealPlanning.monthMeals[todayDate]
  );

  const addStackedRecipe = useAddStackedRecipe()


  useEffect(() => {
    const stackedRecipe = JSON.parse(localStorage.getItem("stackedRecipe"));
    stackedRecipe?.id && addStackedRecipe()
  //   // dispatch(
  //   //   infoAlertActions.setAlert({
  //   //     title: "Click on the desired date to plan meal.",
  //   //     messageType: "info",
  //   //   })
  //   // );
  }, []);

  console.log("meal planning")

  return (
    <>
      <Nav />
      <StyledPlanning>
        <h3>Meal Planning</h3>
        <div className="wrapper">
          <Calendar />
          <div className="content">
            <h4>Today Meals</h4>
            <StyledMeals>
              <h5 className="heading">
                {moment(new Date()).format("dddd, MMM Do, YYYY")}
              </h5>
              <TitleOnlyRecipes meals={meals} recipePlanDate={todayDate} />
            </StyledMeals>
          </div>
        </div>
      </StyledPlanning>
      <Footer />
    </>
  );
}

const StyledPlanning = styled.section`
  width: var(--tablet-width);
  margin: var(--section-margin) auto;
  h3{
    margin-bottom: var(--heading-margin-sm);
  }
  button {
    background-color: transparent;
    color: black;
    border-radius: 0;
    min-width: fit-content;
  }
  .react-calendar__month-view__days button {
    border: 1px solid var(--text-light-color);
  }
  img {
    max-width: 80px;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: var(--tablet-width);
    gap: 1.4rem;
    .calendar {
      flex: 1 1 600px;
      .fc {
        min-height: 60vh;
      }
      .fc-h-event {
        background-color: #00ba34;
        border-color: #00ba34;
      }
      .fc-button-group span {
        color: white;
      }
      .fc-daygrid-day-frame {
        cursor: pointer;
        &:hover {
          background-color: var(--yellow-color);
        }
        .fc-daygrid-day-events {
          pointer-events: none;
          .fc-event-title-container {
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
    .content {
      .tags,
      .activity,
      .stock {
        box-shadow: 3px 3px 12px 0px #78787840;
        padding: 4px;
      }
      .tags {
        & :nth-child(2) {
          color: var(--green-color);
        }
        & :nth-child(3) {
          color: var(--secondary-color);
        }
        margin-bottom: 30px;
      }
      .stock {
        margin-bottom: 30px;
        border: 1px solid var(--shadow-light);
        padding: 20px;
        border-radius: 24px;
        .title,
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .item {
          justify-content: flex-start;
          gap: 10px;
          h5 {
            color: var(--text-color);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    h3 {
      text-align: center;
    }
  }
`;

const StyledMeals = styled.div`
  margin-bottom: 10px;
  border: 1px solid var(--shadow-light);
  padding: 8px;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 0px #78787840;
  .heading {
    font-weight: 600;
  }
  svg {
    color: var(--green-color);
  }
`;


export default MealPlanning;
