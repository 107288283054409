import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import LoadingButton from "../LoadingButton";
import { useRef } from "react";
import TypewriterBar from "../search/TypewriterBar";
import RecipesLoading from "../recipes/RecipesLoading";
import useGenerateImg from "../../hooks/useGenerateImg";
import {
  fetchLongRecipes,
  fetchRecipeIngredients,
  fetchRecipeTitles,
  fetchShortRecipes,
} from "../../utils/fileToRecipes";
import AiRecipeGenerating from "../search/AiRecipeGenerating";
import { db } from "../../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import { doc, writeBatch } from "firebase/firestore";
import { userRecipesActions } from "../../store/userRecipesSlice";
import { alertActions } from "../../store/alertSlice";
import { modalActions } from "../../store/modalSlice";

let generatedImages = {};
export default function ConvertToRecipes({ backdropHandler, hideCloseIcon }) {
  const [dragActive, setDragActive] = useState(false);
  const [generatedRecipes, setGeneratedRecipes] = useState({
    data: [],
    loading: false,
    error: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [titlesAndIngredients, setTitlesAndIngredients] = useState({});
  const [saveButton, setSaveButton] = useState({
    active: false,
    loading: false,
  });

  const fileInputRef = useRef(null);

  const uid = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();

  const imageGeneration = useGenerateImg();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setDragActive(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload([...files]);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    handleFileUpload([...files]);
  };

  const chooseFilesHandler = () => {
    fileInputRef.current.click();
  };

  // const handleFileUpload = async (files) => {
  //   setGeneratedRecipes({ data: [], loading: true });

  //   const allowedTypes = [
  //     "image/png",
  //     "image/jpg",
  //     "image/jpeg",
  //     "application/pdf",
  //   ];
  //   let validatedFiles = files.filter(async (file) => {
  //     return allowedTypes.includes(file.type);
  //   });

  //   if (validatedFiles.length !== files.length) {
  //     setGeneratedRecipes({
  //       erorr: "Only .png, .jpg, .jpeg and .pdf files are allowed.",
  //       data: null,
  //       loading: false,
  //     });
  //     return;
  //   }
  //   setUploadedFiles(validatedFiles);

  //   setGeneratedRecipes({
  //     data: [],
  //     loading: true,
  //     error: "",
  //   });

  //   try {
  //     let recipeNames = await fetchRecipeTitles(validatedFiles);
  //     setTitlesAndIngredients(recipeNames); //only setting the recipe names first

  //     let recipeNamesAndIngredients = await fetchRecipeIngredients(recipeNames);
  //     setTitlesAndIngredients(recipeNamesAndIngredients); //setting names with ingredients

  //     const currentDate = new Date().toISOString();

  //     Object.entries(recipeNamesAndIngredients).forEach(
  //       async ([title, ingredients]) => {
  //         let id = Math.random().toString(16).slice(2);
  //         generateRecipeImage(title, ingredients, id);
  //         let shortRecipe = {};
  //         fetchShortRecipes(title, ingredients).then((res) => {
  //           console.log("fetched", res);
  //           const image = generatedImages[id];
  //           shortRecipe = {
  //             ...res,
  //             recipeType: "converted",
  //             image,
  //             id,
  //             imageGenerating: image ? false : true,
  //             ingredients: ingredients,
  //             title,
  //           };
  //           setGeneratedRecipes((current) => ({
  //             data: [...current.data, shortRecipe],
  //             loading: false,
  //             error: "",
  //           }));
  //         });

  //         let longRecipe = await fetchLongRecipes(title, ingredients);
  //         console.log("long recipes", longRecipe);
  //         const image = generatedImages[id];
  //         longRecipe = {
  //           ...longRecipe,
  //           ...shortRecipe, //shortRecipe should deconstruct after longRecipe to apply it's properties
  //           image,
  //           id,
  //           uid,
  //           imageGenerating: false,
  //           generationDate: currentDate,
  //           recipeSource: "gpt",
  //         };

  //         setGeneratedRecipes((current) => {
  //           const filtered = current.data.filter((rec) => rec.id !== id);
  //           console.log("filtered", filtered);
  //           return {
  //             data: [longRecipe, ...filtered],
  //             loading: false,
  //             error: "",
  //           };
  //         });

  //         dispatch(
  //           userRecipesActions.appendUserRecipes({
  //             recipeType: "converted",
  //             data: longRecipe,
  //           })
  //         );
  //         // await addDoc(collection(db, "convertedRecipes"), {
  //         //   ...longRecipe,
  //         //   uid,
  //         // });
  //         fileInputRef.current.value = null;
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Failed to get response from GPT:", error);
  //     setGeneratedRecipes({
  //       data: null,
  //       error: error.message,
  //       loading: false,
  //     });
  //   } finally {
  //     setSaveButton({ active: true, loading: false });
  //   }
  // };

  const handleFileUpload = async (files) => {
    setGeneratedRecipes({ data: [], loading: true });

    const allowedTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
    ];

    let validatedFiles = files.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validatedFiles.length !== files.length) {
      setGeneratedRecipes({
        error: "Only .png, .jpg, .jpeg and .pdf files are allowed.",
        data: null,
        loading: false,
      });
      return;
    }

    setUploadedFiles(validatedFiles);
    setGeneratedRecipes({
      data: [],
      loading: true,
      error: "",
    });

    try {
      const recipeNames = await fetchRecipeTitles(validatedFiles);
      setTitlesAndIngredients(recipeNames); // only setting the recipe names first

      const recipeNamesAndIngredients = await fetchRecipeIngredients(
        recipeNames
      );
      setTitlesAndIngredients(recipeNamesAndIngredients); // setting names with ingredients

      const currentDate = new Date().toISOString();

      const recipePromises = Object.entries(recipeNamesAndIngredients).map(
        async ([title, {ingredients, steps}]) => {
          try {
            const id = Math.random().toString(16).slice(2);
            generateRecipeImage(title, ingredients,steps, id);

            const shortRecipeRes = await fetchShortRecipes(title, ingredients, steps);
            const image = generatedImages[id];


            const shortRecipe = {
              ...shortRecipeRes,
              recipeType: "converted",
              image,
              id,
              imageGenerating: image ? false : true,
              ingredients,
              title,
              steps
            };

            setGeneratedRecipes((current) => ({
              data: [...current.data, shortRecipe],
              loading: false,
              error: "",
            }));

            
            const longRecipeRes = await fetchLongRecipes(title, ingredients, steps)
            const longRecipe = {
              ...longRecipeRes,
              ...shortRecipe, // shortRecipe should deconstruct after longRecipe to apply its properties
              image: generatedImages[id],
              id,
              uid,
              imageGenerating: false,
              generationDate: currentDate,
              recipeSource: "gpt",
            };

            
            console.log("image url",generatedImages,longRecipe)

            setGeneratedRecipes((current) => {
              const filtered = current.data.filter((rec) => rec.id !== id);
              return {
                data: [longRecipe, ...filtered],
                loading: false,
                error: "",
              };
            });
          } catch (error) {
            console.log("error while converting recipe", error);
            setGeneratedRecipes({
              data: null,
              error: error.message,
              loading: false,
            });
          }

          // dispatch(
          //   userRecipesActions.appendUserRecipes({
          //     recipeType: "converted",
          //     data: longRecipe,
          //   })
          // );

          // Optionally save to Firestore
          // await addDoc(collection(db, "convertedRecipes"), {
          //   ...longRecipe,
          //   uid,
          // });
        }
      );

      await Promise.all(recipePromises);
      setSaveButton({ active: true, loading: false });
      fileInputRef.current.value = null;
    } catch (error) {
      console.error("error while converting recipes", error);
      setGeneratedRecipes({
        data: null,
        error: error.message,
        loading: false,
      });
    }
  };

  async function saveRecipesToFirebase() {
    if (!uid) {
      dispatch(modalActions.setLoginModalActive(true));
      localStorage.setItem(
        "convertedRecipes",
        JSON.stringify(generatedRecipes.data)
      );
      return;
    }
    setSaveButton({ active: true, loading: true });
    // Create a batch instance
    const batch = writeBatch(db);

    // Iterate over each document and set its specific document ID and data
    generatedRecipes.data.forEach((docData) => {
      docData = { ...docData, uid };
      const docRef = doc(db, "convertedRecipes", docData.id); // Replace 'collectionName' with your collection name
      batch.set(docRef, docData);
      dispatch(
        userRecipesActions.appendUserRecipes({
          recipeType: "converted",
          data: docData,
        })
      );
      localStorage.removeItem("convertedRecipes")
    });

    // Commit the batch
    try {
      await batch.commit();
      dispatch(
        alertActions.setAlert({
          title: "Recipes Saved Successfully",
          messageType: "success",
        })
      );
      console.log("Documents successfully added with specified IDs!");
    } catch (error) {
      dispatch(
        alertActions.setAlert({
          title: "Unable to save recipes",
          messageType: "error",
        })
      );
      console.error("Error writing documents: ", error);
    } finally {
      setSaveButton({ active: true, loading: false });
    }
  }

  async function generateRecipeImage(title, ingredients,steps,id){
    console.log("title", title, ingredients);
    try {
      const image = await imageGeneration(title, ingredients,steps,id)
      generatedImages = { ...generatedImages, [id]: image };
    } catch (error) {
      console.error("Error while generating image:", error);
      generatedImages = { ...generatedImages, [id]: null };
    }
  }

  useEffect(() => {
    const savedGeneratedRecipes = JSON.parse(
      localStorage.getItem("convertedRecipes")
    );
    if (savedGeneratedRecipes?.length > 0) {
      setGeneratedRecipes({
        data: savedGeneratedRecipes,
        loading: false,
      });
      setSaveButton(current => ({...current, active: true}))
    }
  }, []);

  return (
    <StyledConvertRecipes>
      {!hideCloseIcon && (
        <div className="heading">
          <h4>Convert Files to Recipes</h4>
          <button className="icon" onClick={backdropHandler}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        </div>
      )}

      <div className="upload-files">
        <div
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`upload-box ${dragActive ? "drag-active" : ""}`}
        >
          <CloudUploadOutlinedIcon
            htmlColor="var(--text-light-color)"
            sx={{ width: 80, height: 80 }}
          />
          <h5>Drop your files here to upload</h5>
          <p>Works with .PNG, .JPG, .JPEG and .PDF files</p>
          {uploadedFiles.length > 0 && (
            <div className="uploaded-files">
              {uploadedFiles.map((file, index) => (
                <p key={index} className="uploaded-file">
                  Uploaded File: {file.name}
                </p>
              ))}
            </div>
          )}
          <input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".png, .jpg, .jpeg, .pdf"
            multiple
          />
          <LoadingButton
            title={"Choose Files"}
            loading={generatedRecipes.loading}
            onClick={chooseFilesHandler}
          />
        </div>
      </div>

      {generatedRecipes.loading ? (
        <TypewriterBar />
      ) : (
        <div className="extra-height" />
      )}
      {generatedRecipes.loading &&
      Object.keys(titlesAndIngredients).length > 0 ? (
        <AiRecipeGenerating recipes={titlesAndIngredients} />
      ) : Array.isArray(generatedRecipes?.data) &&
        generatedRecipes.data.length > 0 ? (
        <>
          <RecipesLoading
            title=""
            data={structuredClone(generatedRecipes)}
            skeletonValue={3}
          />
          {saveButton.active && (
            <LoadingButton
              title={`Save Recipe${
                generatedRecipes.data.length > 1 ? "s" : ""
              }`}
              loading={saveButton.loading}
              onClick={saveRecipesToFirebase}
              className="secondary-btn"
            />
          )}
        </>
      ) : (
        <p className="error">{generatedRecipes.error}</p>
      )}
    </StyledConvertRecipes>
  );
}

const StyledConvertRecipes = styled.div`
  min-height: 70vh;
  h5 {
    font-weight: 600;
  }
  .extra-height {
    height: 47.15px;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 8px 0;
  }

  .upload-files {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }

  .error {
    color: red;
    text-align: center;
  }

  .upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    max-width: 500px;
    min-height: 280px;
    border: 2px dashed var(--secondary-color);
    padding: 16px;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
    h5 {
      margin-top: 1.8rem;
    }
    p {
      margin: 10px 0;
      font-size: 14px;
    }
    .uploaded-file {
      color: green;
    }
  }

  .drag-active {
    background-color: #e5f2fe;
    border-color: #2196f378;
  }
  .uploaded-files {
    margin-top: 10px;
  }
  input[type="file"] {
    display: none;
  }

  .secondary-btn {
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
    margin: auto;
  }

  .generatedRecipes {
    margin-top: 20px;
    text-align: left;
    width: 100%;
    max-width: 400px;

    h5 {
      margin-bottom: 10px;
    }

    p {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
`;
