import React, { useState } from "react";
import styled from "styled-components";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { CiCalendar } from "react-icons/ci";
import { useLocation } from "react-router";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

export default function CartHeader({ headingId, toggleSelection, setCartData }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("ASEC");
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [displayDateRange, setDisplayDateRange] = useState("");
  const urlPath = useLocation().pathname;

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setCartData((prev) => {
      const newData = { ...prev };

      return toggleSelection({
        data: newData,
        selectionType: "heading",
        headingId: headingId,
        isChecked,
      });
    });
  };

  const handleDotsClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleDelete = () => {
    setCartData((prev) => {
      const newData = { ...prev };

      const section = newData[headingId];

      if (section.checked) {
        section.items = {};
      } else {
        Object.keys(section.items).forEach((itemId) => {
          if (section.items[itemId].checked) {
            delete section.items[itemId];
          }
        });
      }

      return newData;
    });
  };

  const isDeleteEnabled = (prev) => {
    const section = prev[headingId];
    if (!section) return false;

    if (
      section.checked ||
      Object.keys(section.items).some((itemId) => section.items[itemId].checked)
    ) {
      return true;
    }

    return false;
  };

  const handleSort = (order) => {
    setSortOrder(order);
    setDateRange(null);
    setIsMenuOpen(false);

    setCartData((prev) => {
      const newData = { ...prev };
      const section = newData[headingId];

      if (section && section.items) {
        const itemsArray = Object.entries(section.items);

        itemsArray.sort((a, b) => {
          const idA = a[0];
          const idB = b[0];

          if (order === "DESC") {
            return idB.localeCompare(idA);
          } else {
            return idA.localeCompare(idB);
          }
        });

        const sortedItems = {};
        itemsArray.forEach(([key, value]) => {
          sortedItems[key] = value;
        });

        section.items = sortedItems;
      }

      return newData;
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setDateRange(dates);
      setSortOrder("");
    } else {
      setDateRange(null);
      setSortOrder("ASEC");
    }
    setIsDatePickerVisible(true);
    // setIsMenuOpen(false);
  };

  const handleCustomClick = () => {
    setIsDatePickerVisible(true);
    setIsMenuOpen(false);
  };

  const formatDateRange = (dates) => {
    if (!dates) return "";
    const startDate = dates[0].format("YYYY-MM-DD");
    const endDate = dates[1].format("YYYY-MM-DD");
    return `${startDate} to ${endDate}`;
  };

  return (
    <StyledCartHeader>
      <div className="main_container">
        <div className="left_side">
          <div className="recipe_checkbox">
            <input type="checkbox" onChange={handleHeaderCheckboxChange} />
            <h5>{headingId}</h5>
          </div>

          {/* {urlPath === "/cart" && (
            <div className="date_picker">
              <label>Date</label>
              <HiOutlineDotsVertical
                className="dots_icon"
                onClick={handleDotsClick}
              />

              {isMenuOpen && (
                <div className="dropdown_menu">
                  <div className="menu_item" onClick={() => handleSort("DESC")}>
                    <FaArrowUp className="dropdown_icons" />
                    <p>Sort by DESC</p>
                  </div>
                  <div className="divider" />
                  <div className="menu_item" onClick={() => handleSort("ASEC")}>
                    <FaArrowDown className="dropdown_icons" />
                    <p>Sort by ASEC</p>
                  </div>
                  <div className="divider" />
                  <div className="menu_item" onClick={handleCustomClick}>
                    <CiCalendar
                      style={{
                        color: "var(--gray-color)",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <p>Custom</p>
                  </div>
                </div>
              )}
              <p>
                Sorted by: {dateRange ? formatDateRange(dateRange) : sortOrder}
              </p>
            </div>
          )} */}
        </div>

        {/* {urlPath === "/cart" ? (
          <>
            <div className="right_side">
              <RiDeleteBin6Line
                className="delete_icon"
                onClick={() => {
                  setCartData((prev) => {
                    if (isDeleteEnabled(prev)) {
                      handleDelete();
                    }
                    return prev;
                  });
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )} */}
      </div>

      {isDatePickerVisible && (
        <div className="date_range_picker">
          <RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
            onOpenChange={(open) => !open && setIsDatePickerVisible(false)}
          />
        </div>
      )}
    </StyledCartHeader>
  );
}

const StyledCartHeader = styled.div`
  width: 95%;
  margin: 0 auto;
  position: relative;

  .main_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    .left_side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;

      .recipe_checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
      }

      .date_picker {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: relative;

        .dots_icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .dropdown_menu {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          background: white;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 4px 8px var(--shadow);
          z-index: 10;
          width: 180px;

          .menu_item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            cursor: pointer;
            &:hover {
              background: #f5f5f5;
            }

            .dropdown_icons {
              color: var(--gray-color);
            }
          }

          .divider {
            border-top: 1px solid #ccc;
            margin: 4px 0;
          }
        }
      }
    }

    .right_side {
      .delete_icon {
        color: red;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .disabled {
        opacity: 0.2;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
`;
