import React, { useState } from "react";
import styled from "styled-components";
import SearchableTabs from "../components/SearchableTabs";
import useFetch from "../hooks/useFetch";
import RecipesLoading from "../components/recipes/RecipesLoading";
import { useNavigate } from "react-router";
import { Element } from "react-scroll";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../authentication/firebase";
import { useEffect } from "react";

export default function LandingExplore() {
  const navigate = useNavigate();
  const [generatedRecipes, setGeneratedRecipes] = useState({
    data: [],
    loading: false,
  });


  async function getUserRecipes() {
    const recipesIds = ["0015261c83b73", "169e06586423a", "d65847fb1a623", "022790464b4b2", "7feb7624bbcfa"]
    setGeneratedRecipes({ data: [], loading: true });
    try {
      const q = query(
        collection(db, "gptRecipes"),
        // orderBy("generationDate", "desc"),
        where("id", "in", recipesIds)
      );
      const docsRef = await getDocs(q);
      let fetchedRecipes = [];
      docsRef.forEach((doc) => fetchedRecipes.push(doc.data()));
      console.log("fetch", fetchedRecipes);
      setGeneratedRecipes({ data: fetchedRecipes, loading: false });
    } catch (e) {
      setGeneratedRecipes({ data: [], loading: false });
      console.log(e);
    }
  }

  useEffect(() => {
    getUserRecipes()
  }, [])

  return (
    <Element name="recipes">
      <StyledExplore>
        <h3>Explore Recipes</h3>
        <RecipesLoading addBtn={false} data={generatedRecipes} title="" />
        <button
        className="load-more-gtag"
          onClick={(e) =>
            // navigate("/login", { state: { from: "/dashboard/explore" } })
            navigate("/dashboard/explore")
          }
        >
          Load More
        </button>
      </StyledExplore>
    </Element>
  );
}

const StyledExplore = styled.section`
  text-align: center;
  margin: 0 5%;
  h3 {
    margin-bottom: var(--heading-margin);
  }
  .load-more-gtag {
    background-color: var(--secondary-color);
    &:hover {
      background-color: var(--secondary-dark-color);
    }
  }
  @media screen and (max-width: 900px) {
    margin: var(--section-margin) 0%;
  }
`;
