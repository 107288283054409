import styled from "styled-components";
import { Avatar, Tooltip, useMediaQuery } from "@mui/material";
import logo from "../assets/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExploreIcon from "@mui/icons-material/Explore";
import SearchIcon from "@mui/icons-material/Search";
import SpaIcon from "@mui/icons-material/Spa";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modalSlice";

function Nav() {
  // const theme = useTheme();
  // const [drawerState, setDrawerState] = useState(false);
  // const toggleDrawer = (open) => {
  //   setDrawerState(open);
  // };
  const navigate = useNavigate();
  const mediaQueryMobile = useMediaQuery("(max-width: 900px)");
  const mediaQuery = useMediaQuery("(min-width: 900px)");
  const dispatch = useDispatch();
  const { photoURL, uid, email, displayName } = useSelector(
    (state) => state.user
  );

  function openLoginModal() {
    dispatch(modalActions.setLoginModalActive(true));
  }

  return (
    <>
      {mediaQueryMobile && (
        <MobileNav>
          <div className="nav-tabs">
            <Tooltip title="Plan your daily meals">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/meal-planning"
              >
                <CalendarMonthIcon />
                <span>Planning</span>
              </NavLink>
            </Tooltip>
            <Tooltip title="Explore thousands of recipes">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/explore"
              >
                <ExploreIcon />
                <span>Explore</span>
              </NavLink>
            </Tooltip>
            <Tooltip title="Generate custom recipes with our Ai">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/ai-recipes"
              >
                <SearchIcon />
                <span>Ai Recipes</span>
              </NavLink>
            </Tooltip>
            <Tooltip title="See nutrition stats of your meals">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/meal-nutrition"
              >
                <SpaIcon />
                <span>Nutrition</span>
              </NavLink>
            </Tooltip>
          </div>
        </MobileNav>
      )}
      <StyledNav>
        <div className="nav-logo">
          <img alt="" src={logo} onClick={(e) => navigate("/dashboard")} />
        </div>
        {mediaQuery && (
          <div className="nav-tabs">
            <Tooltip title="Plan your daily meals">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/meal-planning"
              >
                Meal Planning
              </NavLink>
            </Tooltip>
            <Tooltip title="Explore thousands of recipes">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/explore"
              >
                Explore
              </NavLink>
            </Tooltip>
            <Tooltip title="Generate custom recipes with our Ai">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/ai-recipes"
              >
                Ai Recipes
              </NavLink>
            </Tooltip>
            <Tooltip title="See nutrition stats of your meals">
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "var(--primary-color)" } : {};
                }}
                to="/meal-nutrition"
              >
                Meals Nutrition
              </NavLink>
            </Tooltip>
          </div>
        )}
        <div className="nav-buttons">
          <Tooltip title="Shopping Cart">
            <ShoppingCartRoundedIcon
              onClick={() => navigate("/cart")}
              fontSize="medium"
            />
          </Tooltip>
          <Tooltip title="Favorite Recipes">
            <FavoriteIcon
              onClick={() => navigate("/favorites")}
              fontSize="medium"
              color="error"
            />
          </Tooltip>
          <Tooltip title={uid ? "User Profile" : "Login"}>
            {uid ? (
              <Avatar
                src={photoURL}
                onClick={() => navigate("/profile")}
                style={{
                  fontSize: "20px",
                  border: "2px solid #f79c16",
                  backgroundColor: "#85ce33",
                }}
                sx={{ width: 40, height: 40 }}
              >
                {displayName
                  ? displayName.split(" ")[0]?.[0] +
                    displayName.split(" ")[1]?.[0]
                  : email?.substring(0, 2)?.toUpperCase() || "BB"}
              </Avatar>
            ) : (
              <button
                onClick={openLoginModal}
                className="blue-btn"
              >
                Login
              </button>
            )}
          </Tooltip>
        </div>
      </StyledNav>
    </>
  );
}

const MobileNav = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 46px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -1px 8px 0px var(--shadow);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  z-index: 10;
  .nav-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 5%;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--text-light-color);
      span {
        color: inherit;
        font-size: 0.8rem;
      }
    }
  }
`;
const StyledNav = styled.div`
  height: 60px;
  z-index: 10;
  padding: 0% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 1px 8px var(--shadow);
  position: sticky;
  top: 0;
  left: 0;
  a {
    color: var(--text-light-color);
  }
  .nav-logo,
  .nav-tabs,
  .nav-buttons {
    display: flex;
    align-items: center;
  }
  .nav-logo {
    flex: 1 1 20%;
    img {
      cursor: pointer;
      max-width: 40px;
    }
  }
  .nav-tabs {
    flex: 1 1 60%;
    justify-content: space-evenly;
    a {
      margin-right: 20px;
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .nav-buttons {
    flex: 1 1 20%;
    justify-content: flex-end;
    gap: 10px;
    * {
      cursor: pointer;
    }
    div {
      margin-left: 5%;
    }
  }
  @media (min-width: 1200px) {
    .nav-logo {
      flex: 1 1 25%;
    }
  }
  @media (max-width: 600px) {
    .nav-buttons {
      button {
        margin-left: 0;
      }
    }
  }
`;

export default Nav;
