import React, { useRef } from "react";
import styled from "styled-components";
import LoadingButton from "./LoadingButton";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useState } from "react";
import { motion } from "framer-motion";
import firebaseApi from "../utils/firebaseApi";
import { useDispatch } from "react-redux";
import { alertActions } from "../store/alertSlice";

export default function ContactForm() {
  const [contactForm, setContactForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const emailRef = useRef();
  const messageRef = useRef();
  const dispatch = useDispatch();

  const animationVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };

  async function submitHandler(e) {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const url = `/sendContactEmail?email=${emailRef.current.value}&message=${messageRef.current.value}`;
      await firebaseApi.get(url);
      dispatch(
        alertActions.setAlert({
          title: "Message sent successfully",
          messageType: "success",
        })
      );
      setContactForm(false)
    } catch (error) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to send message",
          messageType: "error",
        })
      );
      console.log("form submission error", error.message);
    }
    setSubmitLoading(false);
  }

  return (
    <StyledForm>
      {contactForm && (
        <motion.form
          initial={{ opacity: 0 }}
          animate={contactForm ? "open" : "closed"}
          variants={animationVariants}
          onSubmit={submitHandler}
        >
          <h4>Contact Us</h4>
          <input required={true} type="email" placeholder="Email" ref={emailRef} />
          <textarea
            required={true}
            name="message"
            id=""
            placeholder="Message"
            ref={messageRef}
          ></textarea>
          <LoadingButton
            loading={submitLoading}
            title="Submit"
            type="submit"
          />
        </motion.form>
      )}
      <button
        className="contact-btn"
        onClick={(e) => setContactForm((current) => !current)}
        type="button"
      >
        <ChatBubbleIcon fontSize="small" />
        <span>Contact us</span>
      </button>
    </StyledForm>
  );
}

const StyledForm = styled.section`
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  form {
    padding: 1rem 3rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 1px 8px 4px var(--shadow);
    border-radius: 1rem;
    textarea {
      min-width: 250px;
      max-width: 280px;
      min-height: 80px;
      max-height: 120px;
      padding: 6px 14px;
    }
    input,
    textarea {
      width: 250px;
      border-radius: 8px;
    }
  }
  .contact-btn {
    align-self: flex-end;
    background-color: var(--green-color);
    display: inline-flex;
    align-items: center;
    gap: 8px;
    min-width: auto;
    max-width: 38px;
    overflow: hidden;
    padding: 8px 9.5px;
    box-shadow: 0px 1px 4px 1px var(--shadow);
    span {
      color: inherit;
      white-space: nowrap;
    }
    &:hover {
      background-color: #76a246;
      max-width: 200px;
    }
  }
  @media (max-width: 500px) {
    form {
      padding: 1rem;
    }
  }
`;
