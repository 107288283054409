import { Skeleton, useMediaQuery } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function SkeletonLoader({ title, skeletonValue }) {
  const mediaQuery = useMediaQuery("(max-width: 600px)");

  return (
    <StyledSkeleton>
      <div className="boxes">
        {Array.from({length: skeletonValue || 4}).map((item, index) => (
          <Skeleton
            sx={{ bgcolor: "grey.300", borderRadius: "12px" }}
            variant="rectangular"
            width={mediaQuery ? 280 : 180}
            height={mediaQuery ? 100 : 205}
            key={index}
          />
        ))}
      </div>
    </StyledSkeleton>
  );
}

const StyledSkeleton = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 20px 10px;
  .boxes {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;
    margin: 10px;
  }
`;
