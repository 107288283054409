import React from "react";
import styled from "styled-components";

export default function CartIngredients({
  recipe,
  headingId,
  toggleSelection,
  setCartData,
}) {
  const { ingredients } = recipe;

  const handleCheckboxChange = (e, index) => {
    const isChecked = e.target.checked;
    setCartData((prev) => {
      const newData = { ...prev };

      return toggleSelection({
        data: newData,
        selectionType: "ingredients",
        headingId: headingId,
        recipeId: recipe.id,
        ingredientIndex: index,
        isChecked,
      });
    });
  };

  return (
    <StyledIngredients>
      <h4>Ingredients</h4>
      <div className="main_container">
        {ingredients.data.map((ingredient, index) => (
          <div className="ingredient" key={index}>
            <input
              type="checkbox"
              id={`ingredient-${index}`}
              checked={ingredient.checked}
              onChange={(e) => handleCheckboxChange(e, index)}
            />

            <div className="title">
              <label htmlFor={`ingredient-${index}`} className="ingredients">
                {ingredient.nameClean}
              </label>
              <div className="amount">
                <p>{ingredient.measures.us.amount}</p>
                <p>{ingredient.measures.us.unitShort}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </StyledIngredients>
  );
}

const StyledIngredients = styled.div`
  width: 95%;
  margin: 36px auto 0 auto;
  h4 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .main_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2%;

    .ingredient {
      width: calc(50% - 0.5rem);
      min-height: 84px;
      background-color: #fbfbfb;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      box-sizing: border-box;

      input {
        margin-right: 1rem;
      }
      .title{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;

        .amount{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        }
        
      }
    }
  }

  @media (max-width: 640px) {
    .main_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ingredient {
        width: 90%;
      }
    }
  }
`;
