import { db } from "../authentication/firebase";
import firebaseApi from "./firebaseApi";
import { query, collection, where, getDocs, limit } from "firebase/firestore";

export async function sendWelcomeEmail(email) {
  try {
    const response = await firebaseApi.get(`/sendWelcomeEmail?email=${email}`);
  } catch (e) {
    console.log("error while sending welcome email", e);
  }
}


export async function sendWeeklyEmail(email) {
  try {
    const response = await firebaseApi.get(
      `/sendWeeklyRecipesEmail?email=${email}`
    );
  } catch (e) {
    console.log("error while sending weekly recipes email", e);
  }
}

export async function getDocIds() {
  try {
    // Create a query with the array-contains-any filter
    const q = query(
      collection(db, "gptRecipes"),
      where("dishTypes", "array-contains", "lunch"),
      limit(500)
    );

    // Get the query snapshot
    const querySnapshot = await getDocs(q);

    // Create an array to store document IDs
    const docIds = [];

    // Iterate over the documents and push their IDs into the array
    querySnapshot.forEach((doc) => {
      docIds.push(doc.id); // Push document ID into the array
    });

    // Log or return the array of document IDs
    console.log("Document IDs:", JSON.stringify(docIds));
    return docIds;
  } catch (error) {
    console.error("Error fetching document IDs:", error);
    throw error;
  }
}