export const trackEvent = (action, category, label, value)=>{
    if(window.gtag){
      console.log('Event Triggered:', { action, category, label, value });
       window.gtag('event', action,{
        event_category: category,
        event_label: label,
        value: value,
       }) 
    }else {
        console.warn('Google Analytics gtag is not initialized');
    }
}