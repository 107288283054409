import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const date = moment(new Date()).format("YYYY-MM-DD");

const mealPlanning = createSlice({
  name: "meal planning",
  initialState: {
    monthMeals: {},
    draggedRecipe: {}
  },
  reducers: {
    monthMeals(state, action) {
      // console.log("month meals", action.payload)
      state.monthMeals = action.payload;
      // state.todayDate = action.payload.todayDate;
    },
    updateMeals(state, action) {
      // state.monthMeals[action.payload[0]][action.payload[1]] =
      //   action.payload[2];
      // if (state.todayDate === action.payload.todayDate)
      // console.log('update meals', action.payload)
      state.monthMeals = {
        ...state.monthMeals,
        [action.payload.date]: {
          ...state.monthMeals[action.payload.date],
          [action.payload.mealType]: action.payload.data,
        },
      };
    },
    deleteMeal(state, action) {
      state.monthMeals = {
        ...state.monthMeals,
        [action.payload.date]: {
          ...state.monthMeals[action.payload.date],
          [action.payload.mealType]: undefined,
        },
      };
      // state.monthMeals =
      // let updatedMeals = {...state.monthMeals}
      // delete updatedMeals[action.payload.date][action.payload.mealtype];
      // console.log("updated", updatedMeals)
      // state.monthMeals = updatedMeals
    },
    setDraggedRecipe(state, action) {
      state.draggedRecipe = action.payload
    }
  },
});

export const mealPlanningActions = mealPlanning.actions;
export const mealPlanningReducer = mealPlanning.reducer;
