import React, { useEffect, useState } from "react";
import useDatabaseRecipes from "../../hooks/useDatabaseRecipes";
import BackdropWrapper from "../modals/BackdropWrapper";
import ConfirmCartModal from "../modals/ConfirmCartModal";
import { useLocation } from "react-router";
import Footer from "../Footer.js";
import Nav from "../Nav.js";
import Cart from "./Cart.js";
import firebaseApi from "../../utils/firebaseApi.js";
import { auth } from "../../authentication/firebase.js";
import AuthLoader from "../../authentication/AuthLoader.js";

export default function CartPage() {
  const [cartData, setCartData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const urlPath = useLocation().pathname;

  const reviewCartHandler = () => {
    setBackdrop((prev) => !prev);
  };

  async function fetchCart() {
    setIsLoading(true);
    try {
      if (auth.currentUser?.uid) {
        const url = `/getSubCollectionsData?docPath=shoppingCart/${auth.currentUser?.uid}`;
        const response = await firebaseApi.get(url, auth.currentUser?.uid);
        const fetchedRecipes = response.data;
        let cartRecipes = {};
        Object.entries(fetchedRecipes).forEach(([month, value]) => {
          Object.entries(value).forEach(([date, data]) => {
            cartRecipes[`${month}-${date}`] = {};
            Object.entries(data).forEach(([mealType, recipe]) => {
              cartRecipes[`${month}-${date}`][recipe.id] = {
                id: recipe.id,
                data: recipe,
                checked: false,
                ingredients: {
                  data: recipe.extendedIngredients
                    ? recipe.extendedIngredients.map((ingredient) => ({
                        ...ingredient,
                        checked: false,
                      }))
                    : [],
                  checked: false,
                },
              };
            });
          });
        });
        // console.log("data", cartRecipes);

        if (Object.keys(cartRecipes)?.length > 0) {
          let filteredData = {};
          Object.entries(cartRecipes).forEach(
            ([date, recipes]) =>
              (filteredData[date] = { checked: false, items: recipes })
          );
          setCartData(filteredData);
        }
      }
    } catch (e) {
      console.log("error while fetching cart", e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCart();
  }, [urlPath]);

  console.log("cart data", Object.keys(cartData).length)

  return (
    <>
      <Nav />
      <BackdropWrapper
        handleBackdrop={reviewCartHandler}
        smallSize={false}
        element={<ConfirmCartModal backdropHandler={reviewCartHandler} />}
        open={backdrop}
      />
      {isLoading ? (
        <AuthLoader /> 
      ) : cartData && Object.keys(cartData)?.length === 0 ? (
        <div
          style={{
            width: "100vw",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="warning"
        >
          <h5>No Item in the cart</h5>
        </div>
      ) : (
          <Cart
            cartData={cartData}
            setCartData={setCartData}
            reviewCartHandler={reviewCartHandler}
            isLoading={isLoading}
          />
      )}
      <Footer />
    </>
  );
}
