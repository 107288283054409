import React, { useState, useEffect } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import CartIngredients from "./CartIngredients";
import BackdropWrapper from "../modals/BackdropWrapper";
import RecipeDetails from "../modals/RecipeDetails";
import { fetchSingleRecipeById } from "../../utils/recipesHandler";
import { motion } from "framer-motion";
import moment from "moment";

export default function CartRecipe({
  recipe,
  headingId,
  toggleSelection,
  setCartData,
}) {
  const [ingredientsOpen, setIngredientsOpen] = useState(false);
  const [servings, setServings] = useState(1);
  const [recipeDetailsModal, setRecipeDetailsModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 641
  );


  const handleRecipeDetailsClick = async () => {
    setRecipeDetailsModal(current => !current);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const truncateTitle = (title) => {
    if (windowWidth <= 640 && title.length > 30) {
      return title.substring(0, 30) + "...";
    }
    return title;
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setCartData((prev) => {
      const newData = { ...prev };

      return toggleSelection({
        data: newData,
        selectionType: "recipe",
        headingId: headingId,
        recipeId: recipe.id,
        isChecked,
      });
    });
  };

  const toggleIngredients = () => {
    setIngredientsOpen((prev) => !prev);
  };

  const incrementServings = () => {
    setServings((prev) => prev + 1);
  };

  const decrementServings = () => {
    if (servings > 1) {
      setServings((prev) => prev - 1);
    }
  };

  const recipeDetailsBackdrop = () => {
    setRecipeDetailsModal((current) => !current);
  };

  return (
    <>
      <BackdropWrapper
        open={recipeDetailsModal}
        handleBackdrop={recipeDetailsBackdrop}
        element={
          <RecipeDetails data={recipe.data} handleBackdrop={recipeDetailsBackdrop} />
        }
      />
      <MainRecipe>
        <div className="recipe_container">
          <div className="main_container">
            <div className="checkbox">
              <input
                type="checkbox"
                checked={recipe.checked}
                onChange={handleCheckboxChange}
              />
            </div>

            <div className="recipe-image">
              <img src={recipe.data?.image} alt={recipe.data?.title} />
            </div>

            <div className="recipe-info">
              <h5 className="recipe-title" onClick={handleRecipeDetailsClick}>
                {truncateTitle(recipe.data.title)}
              </h5>
              <p>Added for: {recipe.data.plannedFor}</p>
              <p>Servings: {recipe.data.servings}</p>
              {/* <p>Date: {moment(recipe.data.dateAdded).format("DD-MM-YYYY")}</p> */}
            </div>

            {/* <div className="serving-btn">
              <div className="buttons">
                <div className="add-btn" onClick={decrementServings}>
                  <p>-</p>
                </div>
                <div className="serving-number">
                  <p>{servings}</p>
                </div>
                <div className="minus-btn" onClick={incrementServings}>
                  <p>+</p>
                </div>
              </div>
              <p>Servings</p>
            </div> */}

            <div className="toggle-icon" onClick={toggleIngredients}>
              {ingredientsOpen ? (
                <IoIosArrowUp className="icon" />
              ) : (
                <IoIosArrowDown className="icon" />
              )}
            </div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={
            ingredientsOpen
              ? { opacity: 1, height: "auto" }
              : { opacity: 0, height: 0 }
          }
          transition={{ duration: 0.3 }}
          style={{ overflow: "hidden" }}
        >
          <hr
            style={{
              margin: "0 2% 0 2%",
            }}
          />
          <CartIngredients
            recipe={recipe}
            headingId={headingId}
            toggleSelection={toggleSelection}
            setCartData={setCartData}
          />
        </motion.div>
      </MainRecipe>
    </>
  );
}

const MainRecipe = styled.div`
  width: 92%;
  margin: 3% auto 0 auto;
  border: 1px solid var(--gray-color);
  border-radius: 24px;
  &:hover {
    border: 1px solid var(--primary-color);
  }

  .recipe_container {
    .main_container {
      display: grid;
      grid-template-columns: 30px auto 3fr 40px;
      grid-template-rows: repeat(3, auto);
      align-items: center;
      gap: 2%;
      padding: .8rem .4rem;
      position: relative;

      .checkbox {
        grid-column: 1 / 2;
        grid-row: 1 / -1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .recipe-image {
        grid-column: 2 / 3;
        grid-row: 1 / -1;
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px var(--shadow);
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .recipe-info {
        grid-column: 3 / 4;
        grid-row: 1 / -1;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        .recipe-title {
          cursor: pointer;
        }
        h5{
          line-height: 1.3;
        }
      }

      .serving-btn {
        grid-column: 4 / 5;
        grid-row: 2 / 4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
        justify-self: end;

        .buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          width: 120px;
          height: 40px;
          border: 1px solid var(--shadow);
          border-radius: 48px;
          background-color: #fff;

          .add-btn,
          .minus-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #00aeb514;
            cursor: pointer;
            font-weight: 550;
            p {
              font-weight: 500;
              color: #002140;
            }
          }
        }
      }

      .toggle-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        .icon {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .recipe_container {
      .main_container {
        grid-template-columns: 20px auto 2fr 20px;
        grid-template-rows: auto auto auto;
        gap: 0.5rem;

        .checkbox {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        .recipe-image {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          width: 75px;
          height: 75px;
          img {
          }
        }

        .recipe-info {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
          p {
            display: none;
          }
        }

        .serving-btn {
          grid-column: 1 / -1;
          grid-row: 3 / 4;
          margin-top: 1rem;
          justify-self: center;
        }

        .toggle-icon {
          grid-column: 4 / 5;
          grid-row: 1 / 2;
          position: static;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;
