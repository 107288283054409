import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bg from "../assets/Login-Signup/login-bg.webp";
import leaf6 from "../assets/Login-Signup/leaf-tomato6.webp";
import leaf4 from "../assets/Login-Signup/leaf-tomato4.webp";
import { auth } from "../authentication/firebase";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import LoadingButton from "../components/LoadingButton";
import BackdropWrapper from "../components/modals/BackdropWrapper";
import ForgotPass from "../components/modals/ForgotPass";
import { useDispatch } from "react-redux";
import { alertActions } from "../store/alertSlice";
import Notification from "../components/modals/Notification";
import { useMediaQuery } from "@mui/material";
import SignInWithoutEmail from "../authentication/SignInWithoutEmail";
// import DownloadPwa from "../components/DownloadPwa";

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [verifyNotification, setVerifyNotification] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mediaQuery = useMediaQuery("(min-width: 950px)");

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      console.log("user", response.user);
      if (response.user.emailVerified) {
        // if (!response.user.displayName) {
        //   const displayName = localStorage.getItem("displayName");
        //   await updateProfile(response.user, {
        //     displayName: displayName,
        //   });
        // }
        // localStorage.removeItem("displayName");
        dispatch(
          alertActions.setAlert({
            title: "Login Successful",
            messageType: "success",
          })
        );
        window.dataLayer.push({
          event: "login-via-form",
        });
        navigate(from);
      } else {
        const verfiyEmail = await sendEmailVerification(response.user);
        console.log("verfiy email", verfiyEmail);
        setVerifyNotification(true);
        dispatch(
          alertActions.setAlert({
            title: "Email not verified",
            messageType: "warning",
          })
        );
      }
    } catch (e) {
      console.log("Failed to login", JSON.stringify(e));
      dispatch(
        alertActions.setAlert({
          title: JSON.parse(JSON.stringify(e)).code,
          messageType: "warning",
        })
      );
    }
    setLoading(false);
  };

  const forgotPassHandler = () => {
    setBackdrop((prev) => !prev);
  };

  const verifyNotificationHandler = () => {
    setVerifyNotification((current) => {
      if (current === true) navigate("/login");
      return !current;
    });
  };

  return (
    <LoginStyled bg={bg}>
      <BackdropWrapper
        handleBackdrop={forgotPassHandler}
        element={<ForgotPass backdropHandler={forgotPassHandler} />}
        smallSize={true}
        open={backdrop}
      />
      <BackdropWrapper
        handleBackdrop={verifyNotificationHandler}
        element={
          <Notification
            heading="Email Verification"
            msg="Email verification link has been sent to your email, please verify and login."
            backdropHandler={verifyNotificationHandler}
          />
        }
        open={verifyNotification}
        smallSize={true}
      />
      <div className="text">
        <img className="img4" src={leaf4} alt="leaf" />
        <img className="img6" src={leaf6} alt="leaf" />
        <h1>Welcome to BreakBread</h1>
        {/* <DownloadPwa /> */}
        <form onSubmit={submitHandler}>
          <div>
            <input
              ref={emailRef}
              placeholder="Email"
              name="email"
              type="email"
              required
            />
          </div>
          <div>
            <input
              placeholder="Password"
              name="password"
              type="password"
              required
              ref={passwordRef}
              pattern=".{8,}"
              title="Password must be at least 8 characters long"
            />
          </div>

          <div className="btns">
            <LoadingButton loading={loading} type="submit" title="Log In" />
            <span onClick={forgotPassHandler} className="forgot-pass">
              Forgot password?
            </span>
            <div>
              <span>Don't have an account?</span>
              <Link to="/signup"> Sign Up</Link>
            </div>
          </div>
        </form>
        {mediaQuery && (
          <div className="space">
            <span>Or</span>
            <div />
          </div>
        )}
        <SignInWithoutEmail />
      </div>
    </LoginStyled>
  );
}
const LoginStyled = styled.div`
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  span {
    color: var(--text-color);
  }
  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
    width: 65%;
    margin-left: auto;
    .img4,
    .img6 {
      position: absolute;
      z-index: -1;
    }
    .img6 {
      top: 50%;
      left: -32%;
    }
    .img4 {
      right: 0%;
    }
    h1 {
      margin-top: 20px;
      color: var(--primary-color);
    }
    form {
      width: 40%;
      a {
        color: var(--secondary-color);
      }
      input {
        height: 50px;
        width: 100%;
      }
      div {
        margin: 18px auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        label {
          float: left;
          margin-bottom: 4px;
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        button {
          background-color: var(--accent-color);
          &:hover {
            background-color: var(--accent-dark-color);
          }
        }
        div {
          margin: 0;
          align-items: center;
          flex-direction: row;
          gap: 4px;
        }
        .forgot-pass {
          cursor: pointer;
          text-decoration: underline;
        }
        a {
          text-decoration: underline;
        }
      }
    }
    .space {
      position: relative;
      width: 38%;
      div {
        width: 100%;
        background-color: var(--text-light-color);
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
      }
      span {
        background-color: white;
        z-index: 10;
        padding: 0 20px;
      }
    }
    .google-button {
      color: black;
      background-color: transparent;
      border: 1px solid var(--text-light-color);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .text {
      width: 100%;
      .img4,
      .img6 {
        width: 80px;
      }
      form {
        width: 80%;
      }
    }
  }
  @media screen and (min-width: 500px) and (max-width: 900px) {
    .text {
      width: 80%;
      .img4,
      .img6 {
        width: 80px;
      }
      form {
        width: 50%;
      }
    }
  }
`;
export default Login;
