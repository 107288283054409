import React, { useEffect, useState } from "react";
import { db } from "../authentication/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { fetchRecipesByIds } from "../utils/recipesHandler";
import RecipesLoading from "../components/recipes/RecipesLoading";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

export default function ShoppingCartRecipes() {
  const uid = useSelector((state) => state.user.uid);
  const [fetchedRecipes, setFetchedRecipes] = useState({
    data: [],
    loading: true,
  });

  async function fetchCartRecipes() {
    try {
      const recipesDoc = await getDoc(doc(db, "shoppingCartRecipes", uid));

      if (recipesDoc.exists()) {
        const recipeIds = Object.keys(recipesDoc.data());
        const recipes = await fetchRecipesByIds(recipeIds);
        setFetchedRecipes({ data: recipes, loading: false });
      }
    } catch (e) {
      setFetchedRecipes((current) => ({ ...current, loading: false }));
      console.log("error while fetching shopping cart recipes", e);
    }
  }

  useEffect(() => {
    fetchCartRecipes()
  }, []);

  return (
    <>
    <Nav />
    <StyledCartRecipes>
      <h3 className="heading">Shopping Cart Recipes</h3>
      <RecipesLoading loading={fetchedRecipes.loading} data={fetchedRecipes} />
    </StyledCartRecipes>
    <Footer />
    </>
  );
}


const StyledCartRecipes = styled.div`
margin: var(--section-margin);
.heading {
    margin-bottom: var(--heading-margin);
}
`
