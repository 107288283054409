import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import ProfileTabs from "../components/profile-settings/ProfileTabs";
import { profileTabItems } from "../utils/profilePageData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../components/Footer";
import { auth } from "../authentication/firebase";
import { useNavigate } from "react-router";
import Nav from "../components/Nav";
import { useDispatch, useSelector } from "react-redux";
import BackdropWrapper from "../components/modals/BackdropWrapper";
import ForgotPass from "../components/modals/ForgotPass";
import uploadImg from "../utils/uploadImg";
import { alertActions } from "../store/alertSlice";
import { updateProfile } from "firebase/auth";
import { userActions } from "../store/userSlice";

let userPreferences,
  profileTabs = [];

function UserProfile() {
  // const [deleteCheck, setDeleteCheck] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { displayName, email, photoURL, uid, preferences } = useSelector(
    (state) => state.user
  );
  const [profileImg, setProfileImg] = useState(photoURL);

  profileTabs = [
    {
      name: "Diet Preferences and Restrictions",
      value: preferences,
    },
    {
      name: "Pantry",
      value: profileTabItems,
    },
  ];

  const fileSelectedHandler = (e) => {
    // setLoading(true);
    let img = {};
    const reader = new FileReader();
    reader.onload = async () => {
      if (reader.readyState === 2) {
        img = { name: e.target.files[0].name, data: reader.result };
        setProfileImg(img.data);
        try {
          const imageUrl = img.data && (await uploadImg(img, "profilePics"));
          console.log("image url", imageUrl);

          await updateProfile(auth.currentUser, {
            photoURL: imageUrl,
          });
          dispatch(userActions.setUserImage(imageUrl));
          dispatch(
            alertActions.setAlert({
              title: "Profile picture changed",
              messageType: "success",
            })
          );
        } catch (error) {
          console.log("e", error);
          dispatch(
            alertActions.setAlert({
              title: "Failed to change profile picture",
              messageType: "error",
            })
          );
        }
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    // setLoading(false);
  };

  const logoutHandler = async () => {
    try {
      await auth.signOut();
      dispatch(
        userActions.setCurrentUser({
          fullname: "",
          email: "",
          uid: "",
          image: "",
          preferences: {},
        })
      );
      navigate("/login");
    } catch (e) {
      console.log("Logout error", e);
    }
  };

  const passHandler = () => {
    setBackdrop((prev) => !prev);
  };

  return (
    <>
      <Nav />
      <StyledEditProfile>
        <BackdropWrapper
        handleBackdrop={passHandler}
          element={<ForgotPass backdropHandler={passHandler} />}
          open={backdrop}
        />
        <div className="text">
          <h3>Your profile</h3>
          <div>
            {uid ? (
              <button onClick={logoutHandler} className="blue-btn">
                Logout
              </button>
            ) : (
              <button onClick={(e) => navigate("/login")} className="blue-btn">
                Login
              </button>
            )}
          </div>
        </div>
        <div className="profile">
          <div className="profile-img">
            <Avatar src={profileImg} sx={{ width: "160px", height: "160px" }} />
            <div>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => fileSelectedHandler(e)}
                id="img-upload"
              />
              {/* <button onClick={() => this.fileInput.click()} className="gray-btn">Upload New Image</button> */}
              {/* <button onClick={(e) => deleteImgHandler()} className="gray-btn">
                Delete
              </button> */}
            </div>
            <div>
              <h5>
                {displayName
                  ?.split(" ")
                  .map(
                    (name) => name.charAt(0).toUpperCase() + name.slice(1) + " "
                  )}
              </h5>
              <span>{email}</span>
            </div>
            {uid && (
              <div className="buttons">
                <label htmlFor="img-upload" className="gray-btn">
                  Change Picture
                </label>
                <button className="blue-btn" onClick={passHandler}>
                  Change Password
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="profile-tabs">
          {profileTabs.map((tab, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: "#002140", width: "38px", height: "38px" }}
                  />
                }
              >
                {index === 0 ? (
                  <div className="profile-tab-header">
                    <h4>{tab.name}</h4>
                    <button
                      className="gray-btn"
                      onClick={(e) => navigate("/profile-setup/preferences")}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <h4>{tab.name}</h4>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <div className="items-div">
                  <ProfileTabs editable={false} tabData={tab.value} />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </StyledEditProfile>
      <Footer />
    </>
  );
}

const StyledEditProfile = styled.div`
  width: var(--tablet-width);
  margin: var(--section-margin) auto 2rem auto;
  h4 {
    font-weight: 500;
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      flex-basis: 75%;
    }
    div {
      * {
        margin: auto 6px;
      }
    }
  }
  .profile {
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    .profile-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      min-height: 45vh;
      img {
        border: 1px solid var(--text-color);
      }
      label {
        cursor: pointer;
        font-size: 16px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
      }
      .buttons {
        flex-direction: row;
      }
    }
  }
  .profile-tabs {
    width: 90%;
    margin: auto;
    .profile-tab-header {
      display: flex;
      gap: 10px;
    }
  }
  @media (max-width: 600px) {
    .profile-tabs {
      width: 100%;
      button {
        height: 30px;
      }
    }
  }
`;

export default UserProfile;
