const toggleSelection = ({
  data,
  selectionType,
  headingId,
  recipeId,
  ingredientIndex,
  isChecked
}) => {
  
  const newData = JSON.parse(JSON.stringify(data));
  
  if (newData) {
    if (selectionType === "heading") {
      if (newData[headingId]) {
        newData[headingId].checked = isChecked;
        newData[headingId].deleteable = isChecked;

        Object.keys(newData[headingId].items).forEach((recipeId) => {
          newData[headingId].items[recipeId].checked = isChecked;
          

          newData[headingId].items[recipeId].ingredients.data.forEach((ingredient) => {
            ingredient.checked = isChecked;
          });
        });
      }
    } else if (selectionType === "recipe") {
      if (newData[headingId]?.items[recipeId]) {
        newData[headingId].items[recipeId].checked = isChecked;
        newData[headingId].deleteable = isChecked;

        newData[headingId].items[recipeId].ingredients.data.forEach((ingredient) => {
          ingredient.checked = isChecked;
        });
        
      }
    } else if (selectionType === "ingredients") {
      if (newData[headingId]?.items[recipeId]?.ingredients.data[ingredientIndex]) {
        newData[headingId].items[recipeId].ingredients.data[ingredientIndex].checked = isChecked;
      }
    }
  }
  return newData;
};

export default toggleSelection;