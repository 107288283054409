import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Element } from "react-scroll";

const faq = [
  {
    question:
      "Is it possible to filter recipes by cuisine or regional preferences?",
    answer: "Yes",
  },
  {
    question: "Can I save or bookmark recipes for future reference?",
    answer: "Yes, you can easily set recipes as your favorite",
  },
  {
    question:
      "Does the app provide nutritional information for the suggested recipes?",
    answer: "Yes",
  },
  {
    question:
      "Are the recipes in the app suitable for beginners or do they require advanced cooking skills?",
    answer: "It's completely suitable for beginners",
  },
  {
    question:
      "Can the app suggest recipes for specific meal types, such as breakfast, lunch, or dinner?",
    answer:
      "Yes, ask our AI recipes for specific meal types and it'll provide you.",
  },
];

export default function Faq() {
  return (
    <Element name="faq">
      <StyledFaq>
        <h3>Frequently Asked Questions</h3>
        <div className="faq">
          {faq.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: "#002140", width: "20px", height: "20px" }}
                  />
                }
              >
                <span>{item.question}</span>
              </AccordionSummary>
              <AccordionDetails>
                <p>{item.answer}</p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </StyledFaq>
    </Element>
  );
}

const StyledFaq = styled.section`
  padding: var(--section-margin) 20%;
  position: relative;
  h3 {
    margin-bottom: var(--heading-margin);
    text-align: center;
  }
  @media screen and (max-width: 900px){
    padding: var(--section-margin) 0;
  }
`;
