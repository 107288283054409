import React from "react";
import styled from "styled-components";
import bbDevice from "../assets/landing-page/bb-device.svg";
import bbAlexa from "../assets/landing-page/bb-alexa.svg";
import bbMobile from "../assets/landing-page/pantry.png";

export default function Upcoming() {
  return (
    <StyledAbout>
      <h3>Upcoming Tech Suite</h3>
      <div className="content">
        <div className="box box1">
          <div>
            <img src={bbMobile} alt="break bread" className="pantry" />
          </div>
          <h5>
            <span>1</span> Pantry Management
          </h5>
          <p>
            Easily manage your pantry. Get recipe suggestions based on
            what's available in your pantry
          </p>
        </div>
        <div className="box box2">
          <div>
            <img src={bbAlexa} alt="break bread" />
          </div>
          <h5>
            <span>2</span> Voice Assistance
          </h5>
          <p>Use our custom voice assistance to help you assist in meal
            planning and cooking food.
          </p>
        </div>
        <div className="box box3">
          <div>
            <img src={bbDevice} alt="break-bread" />
          </div>
          <h5>
            <span>3</span> BB Kiosk
          </h5>
          <p>
            Your meal planning and prepping buddy. AI device you can do
            normal conversations with.
          </p>
        </div>
      </div>
    </StyledAbout>
  );
}

const StyledAbout = styled.section`
  text-align: center;
  margin: auto 5%;
  padding-top: var(--section-margin);
  padding-bottom: var(--section-margin);
  h3 {
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1.4rem;
    .box {
      text-align: center;
      div {
        /* height: 200px; */
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 20px;
        img {
          width: 180px;
        }
        .pantry {
            width: 190px;
        }
      }
      h5 {
        margin-bottom: 12px;
        span {
          font-weight: bold;
          background-color: var(--secondary-color);
          padding: 8px 16px;
          border-radius: 50px;
          color: white;
        }
      }
      p {
        max-width: 34ch;
      }
    }
    .box:first-child{
      h5 span{
        padding: 8px 18px;
      }
    }
  }
`;
