import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RecipeCard from "../recipes/RecipeCard";
import Nav from "../Nav";
import Footer from "../Footer";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../Skeleton";
import SearchableTabs from "../SearchableTabs";
import DropMeals from "./DropMeals";
import useFetch from "../../hooks/useFetch";
import useUserRecipes from "../../hooks/useUserRecipes";
import { infoAlertActions } from "../../store/infoAlertSlice";
// import useAlgolia from "../../hooks/useAlgolia";
import LoadingCircle from "../../styles/LoadingCircle";
import { ToggleButton } from "@mui/material";
import useAddStackedRecipe from "../../hooks/useAddStackedRecipe";
import useDatabaseRecipes from "../../hooks/useDatabaseRecipes";
import ExploreRecipes from "../ExploreRecipes";

let type = "";
var initailSearchInput = [];

function DailyMeals() {
  const { date } = useParams();
  // const fetchUserRecipes = useUserRecipes();
  // const userRecipes = useSelector((state) => state.userRecipes);
  // const dispatch = useDispatch();
  // const [favoriteSelected, setFavoriteSelected] = useState(false);

  // const { fetchedRecipes, setFetchedRecipes, fetchRecipes } = useFetch(
  //   "/complexSearch",
  //   25
  // );
  // console.log("fetched", fetchedRecipes)
  // const searchAlgolia = useAlgolia();
  // const [infiniteScroll, setInfiniteScroll] = useState(true);
  // const [inputs, setInputs] = useState({
  //   cuisine: [],
  //   maxReadyTime: 10000,
  //   query: initailSearchInput,
  // });
  // const observer = useRef();

  // const uid = useSelector(state => state.user.uid)

  // const lastRecipeElementRef = useCallback(
  //   (node) => {
  //     if (fetchedRecipes.loading || !infiniteScroll) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       // if (entries[0].isIntersecting) {
  //       //   fetchRecipes({
  //       //     query: inputs.query.join(" "),
  //       //     cuisine: inputs.cuisine.join(","),
  //       //     maxReadyTime: inputs.maxReadyTime,
  //       //     number: 25,
  //       //     combineResults: true,
  //       //   }).then((recipes) => recipes.length < 25 && setInfiniteScroll(false));
  //       // }
  //       if (entries[0].isIntersecting) {
  //         fetchRecipes({ cuisine: inputs.cuisine, combineResults: true, number: 25 }).then(
  //           (recipes) => recipes?.length < 25 && setInfiniteScroll(false)
  //         );
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [fetchedRecipes.loading, infiniteScroll]
  // );

  // async function searchableTabsHandler(combineResults) {
  //   console.log("cuisine", inputs);
  //   await fetchRecipes({
  //     query: inputs.query.join(" "),
  //     cuisine: inputs.cuisine.join(","),
  //     maxReadyTime: inputs.maxReadyTime || 1000,
  //     number: 25,
  //     combineResults,
  //   });
  //   if (inputs.query.join(" ")) {
  //     const hits = await searchAlgolia(inputs.query.join(" "));
  //     setFetchedRecipes((prevRecipes) => ({
  //       ...prevRecipes,
  //       data: [...prevRecipes.data, ...hits],
  //     }));
  //   }
  // }

  // useEffect(() => {
  //   initailSearchInput = inputs.query;
  // }, [inputs.query]);


  // const userRecipesHandler = (recipeType) => {
  //   type = recipeType;
  //   setFavoriteSelected((current) => {
  //     setInfiniteScroll(current);
  //     return !current;
  //   });
  //   if (userRecipes[`${recipeType}Recipes`].data.length === 0) {
  //     fetchUserRecipes([recipeType]);
  //   }
  // };

  // useEffect(() => {
    // dispatch(
    //   infoAlertActions.setAlert({
    //     title: "Drag and drop the recipes below.",
    //     messageType: "info",
    //   })
    // );
  // }, []);

  return (
    <>
      <Nav />
      <StyledMeals>
        <DropMeals
          date={date}
        />
        <div className="explore">
          <div className="heading">
            <h5>Explore & add new recipes</h5>
          </div>

          {/* <SearchableTabs
            searchableTabsHandler={searchableTabsHandler}
            tabs={[
              {
                name: "Asian",
                value: "korean, chinese, thai, vietnamese, japanese",
              },
              { name: "Mediterranean", value: "greek, mediterranean" },
              { name: "Italian", value: "italian" },
              { name: "French", value: "french" },
              { name: "Indian", value: "indian" },
              { name: "Southern", value: "cajun, southern" },
              {
                name: "Other European",
                value:
                  "german, nordic, british, spanish, easter european, irish, european",
              },
              { name: "Mexican", value: "mexican" },
              { name: "Kosher", value: "jewish" },
              { name: "Middle Eastern", value: "middle eastern" },
              { name: "15min", value: 15 },
              { name: "30min", value: 30 },
              { name: "45min", value: 45 },
            ]}
            inputs={inputs}
            setInputs={setInputs}
          />
          <div className="buttons">
            <ToggleButton
              value="check"
              selected={favoriteSelected}
              onChange={() => userRecipesHandler("favorite")}
            >
              Favorites
            </ToggleButton>
          </div>
          <div className="recipes">
            {favoriteSelected ? (
              userRecipes[`${type}Recipes`].data.length > 0 ? (
                userRecipes[`${type}Recipes`].data.map((recipe, index) => (
                  <RecipeCard
                    key={recipe.id}
                    dragStartHandler={(e) => dragStartHandler(recipe)}
                    data={recipe}
                    selectedDate={date}
                  />
                ))
              ) : userRecipes[`${type}Recipes`].loading === true ? (
                <SkeletonLoader />
              ) : (
                <h5 className="msg">No favorites yet</h5>
              )
            ) : fetchedRecipes.data?.length > 0 ? (
              fetchedRecipes.data?.map((recipe, index) =>
                fetchedRecipes.data.length === index + 1 ? (
                  <div ref={lastRecipeElementRef} key={recipe.id}>
                    <RecipeCard
                      key={recipe.id}
                      data={recipe}
                      dragStartHandler={(e) => dragStartHandler(recipe)}
                      selectedDate={date}
                    />
                  </div>
                ) : (
                  <RecipeCard
                    key={index}
                    dragStartHandler={(e) => dragStartHandler(recipe)}
                    data={recipe}
                    selectedDate={date}
                  />
                )
              )
            ) : fetchedRecipes.loading ? (
              <SkeletonLoader title="" />
            ) : (
              <h5 className="msg">No Recipe Found</h5>
            )}
          </div>
          {infiniteScroll && <LoadingCircle />} */}
          <ExploreRecipes />
        </div>
      </StyledMeals>
      <Footer />
    </>
  );
}

const StyledMeals = styled.section`
  width: var(--tablet-width);
  margin: 10px auto 0 auto;

  .explore {
    display: flex;
    flex-direction: column;
    .heading {
      margin: 1rem auto;
      h5 {
        font-weight: 600;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      /* gap: 10px; */
      margin: 1rem;
      .Mui-selected {
        color: white;
        background-color: var(--green-color) !important;
      }
      button {
        &:hover {
          color: var(--green-color);
        }
      }
    }
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      a {
        color: var(--text-color);
      }
    }
  }
  .recipes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 20px;
    column-gap: 30px;
    .recipe-card {
      margin-bottom: 10px;
    }
    .msg {
      margin: 80px auto;
    }
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export default DailyMeals;
