import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontFamily: "Poppins",
  },
});

export default muiTheme;
