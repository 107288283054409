import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import InstacartLogo from "../assets/Instacart_Carrot.png";
import { saveCartRecipes } from "../utils/recipesHandler";
import { sendRecipeToEmail } from "../utils/emails";
import { useSelector } from "react-redux";
import { instacartApi } from "../utils/apis";
import { useEffect, useState } from "react";

export default function InstacartLink({ data, title }) {
  const mediaQuery600 = useMediaQuery("(max-width: 600px)");
  const email = useSelector((state) => state.user.email);
  const [instacartLink, setInstacartLink] = useState({
    link: "",
    loading: false,
  });

  async function fetchInstacartRecipeLink() {
    const recipeInfo = {
      title: data.title,
      image_url: data.image,
      link_type: "recipe",
      instructions: data.steps,
      servings: data.savedServings,
      cooking_time: data.readyInMinutes,
      external_reference_id: data.id,
      ingredients: data.savedIngredients?.map((ingredient) => ({
        name: ingredient.nameClean,
        display_text:
          ingredient.nameClean?.charAt(0).toUpperCase() +
          ingredient.nameClean?.slice(1),
        measurements: [
          {
            quantity: ingredient?.measures
              ? ingredient?.measures?.metric?.amount
              : ingredient?.amount,
            unit: ingredient?.measures
              ? ingredient?.measures?.metric?.unitLong
              : ingredient?.unit,
          },
        ],
      })),
      landing_page_configuration: {
        partner_linkback_url: `https://www.breakbread.ai/recipe/${data.id}`,
        enable_pantry_items: true,
      },
    };
    try {
      setInstacartLink((current) => ({ ...current, loading: true }));
      const res = await instacartApi.post("/recipe", recipeInfo);
      setInstacartLink({ link: res.data.products_link_url, loading: false });
    } catch (e) {
      console.log("error while fetching instacart recipe link", e);
      setInstacartLink((current) => ({ ...current, loading: false }));
    }
  }

  async function clickHandler() {
    await saveCartRecipes(data.id, "direct");
    await sendRecipeToEmail(email, data);
  }


  useEffect(() => {
    fetchInstacartRecipeLink();
  }, []);

  return (
    <StyledLink>
      {instacartLink.loading ? (
        <CircularProgress
          style={{
            color: "inherit",
            width: mediaQuery600 ? 18 : 20,
            height: mediaQuery600 ? 18 : 20,
          }}
        />
      ) : (
        <a target="blank" href={instacartLink.link} onClick={clickHandler}>
          <img src={InstacartLogo} alt="" />
          {title}
        </a>
      )}
    </StyledLink>
  );
}

const StyledLink = styled.div`
  min-width: 120px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #108910;
  border-radius: 50px;
  color: white;
  &:hover {
    background-color: #0d760d;
  }
  a {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  img {
    width: 20px;
    height: auto;
  }
`;
