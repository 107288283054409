import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const PrivacyPolicy = () => {
  return (
    <>
    <Nav />
    <PrivacyPolicyContainer>
      <h1>Privacy Policy for BREAK BREAD INC</h1>
      <h2>Introduction</h2>
      <p>
        BREAK BREAD INC ("we," "us," or "our") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, and disclose
        information about you when you use our website, mobile applications, or
        other services (collectively, the "Services").
      </p>
      <h2>Information We Collect</h2>
      <p>
        We may collect the following types of information from you:
        <ul>
          <li>
            Personal Information: This includes information that can be used to
            identify you, such as your name, email address, phone number, and
            postal address.
          </li>
          <li>
            Usage Data: This includes information about how you interact with
            our Services, such as the pages you visit, the features you use, and
            the time you spend on our website.
          </li>
          <li>
            Device Information: This includes information about your device,
            such as your IP address, browser type, and operating system.
          </li>
        </ul>
      </p>
      <h2>How We Collect Information</h2>
      <p>
        We collect information from you in the following ways:
        <ul>
          <li>
            When you provide it: We collect information from you when you create
            an account, contact us, or use our Services.
          </li>
          <li>
            Automatically: We may automatically collect information about you
            when you use our Services, such as through cookies, web beacons, and
            similar technologies.
          </li>
        </ul>
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We may use your information for the following purposes:
        <ul>
          <li>To provide you with the Services.</li>
          <li>To personalize your experience on our Services.</li>
          <li>To communicate with you about our Services.</li>
          <li>To analyze and improve our Services.</li>
          <li>To comply with legal requirements.</li>
        </ul>
      </p>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may disclose your information to:
        <ul>
          <li>Our service providers, who help us provide our Services.</li>
          <li>
            Our business partners, who may offer complementary products or
            services.
          </li>
          <li>Legal authorities, if required by law.</li>
        </ul>
      </p>
      <h2>Your Rights</h2>
      <p>
        You may have certain rights regarding your personal information, such as
        the right to access, correct, or delete your information. If you have
        any questions about your rights, please contact us.
      </p>
      <h2>Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to collect information
        about you and your interactions with our Services. You can manage your
        cookie preferences through your browser settings.
      </p>
      <h2>Security</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or electronic storage is completely
        secure.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any material changes by posting a notice on our website.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at steven@familyfoodandhealth.com.
      </p>
    </PrivacyPolicyContainer>
    <Footer />
    </>
  );
};
const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 10px;
  }
`;
export default PrivacyPolicy;
