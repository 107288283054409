import useAddRecipe from "./useAddRecipe";
import spoonacularApi from "../utils/spoonacularApi";
import { useSelector } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../authentication/firebase";

export default function useAddStackedRecipe() {
  const apiKey = process.env.REACT_APP_SPOONACULAR_API_KEY;

  const uid = useSelector((state) => state.user.uid);
  const addRecipeHandler = useAddRecipe();

  async function addStackedRecipe(loginModalUid) {
    const stackedRecipe = JSON.parse(localStorage.getItem("stackedRecipe"));
    const finalUid = uid ? uid : loginModalUid;
    try {
      let res = {}
      if (stackedRecipe?.id && finalUid) {
        if (stackedRecipe?.recipeType === "gpt") {
          const docSnap = await getDoc(
            doc(db, "gptRecipes", stackedRecipe?.id)
          );
          res.data = docSnap.data();
          console.log("gpt recipe data", res.data);
        } else {
          res = await spoonacularApi.get(
            `/${stackedRecipe?.id}/information?includeNutrition=true`,
            {
              params: {
                apiKey: apiKey,
              },
            }
          );
        }
        console.log("stacked", stackedRecipe, finalUid, res.data);
        await addRecipeHandler(
          stackedRecipe?.date,
          res.data,
          stackedRecipe?.mealType,
          stackedRecipe?.selectedServings,
          finalUid
        );
        localStorage.removeItem("stackedRecipe");
      }
    } catch (e) {
      console.log("error in useAddStackedRecipe", e);
    }
  }
  return addStackedRecipe;
}
