import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoadingCircle from "../styles/LoadingCircle";
import RecipeDetails from "../components/modals/RecipeDetails";
import { useLocation, useParams } from "react-router";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { fetchSingleRecipeById } from "../utils/recipesHandler";

let recipeData;

export default function Recipe() {
  const [recipeLoading, setRecipeLoading] = useState(true);
  const params = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  let recipeType = queryParams.get("recipe-type");
  if (recipeType) {
    recipeType = recipeType === "ai" ? "gpt" : recipeType;
  } else {
    recipeType = "gpt";
  }
  useEffect(() => {
    async function getRecipe() {
      recipeData = await fetchSingleRecipeById(params.recipeId, recipeType);
      setRecipeLoading(false);
    }
    getRecipe();
  }, []);

  return (
    <>
      <Nav />
      <StyledRecipe>
        {recipeLoading ? (
          <LoadingCircle />
        ) : recipeData ? (
          <RecipeDetails data={recipeData} hideCloseIcon={true} />
        ) : (
          <h4>No recipe found</h4>
        )}
      </StyledRecipe>
      <Footer />
    </>
  );
}

const StyledRecipe = styled.section`
  display: flex;
  justify-content: center;
  min-height: 90vh;
  width: 60%;
  margin: auto;
  margin-top: var(--heading-margin);
  padding-bottom: 15%;
  @media (max-width: 850px) {
    width: 95%;
  }
  @media (min-width: 850px) and (max-width: 1150px) {
    width: 80%;
  }
`;
