import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    displayName: "",
    email: "",
    uid: null,
    photoURL: "",
    preferences: {},
    info: {}
  },
  reducers: {
    setCurrentUser(state, action) {
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
      state.uid = action.payload.uid;
      state.photoURL = action.payload.photoURL;
      state.preferences = action.payload.preferences || {};
      state.info = action.payload.info || {}
    },
    setUserPreferences(state, action) {
      state.preferences = action.payload;
    },
    setUserInfo(state, action) {
      state.info = action.payload || {}; 
    },
    setQuickPreferences(state, action) {
      state.preferences.cuisine_preferences =
        action.payload.cuisine_preferences;
      state.preferences.diet_preferences = action.payload.diet_preferences;
      state.preferences.intolerances = action.payload.intolerances;
    },
    deleteUserPreferences(state, action) {
      state.preferences[action.payload.filterName].values = state.preferences[
        action.payload.filterName
      ].values.filter((value) => value !== action.payload.filterValue);
    },
    setUserImage(state, action) {
      state.photoURL = action.payload;
    },
  },
});

export const userActions = user.actions;
export const userReducer = user.reducer;
