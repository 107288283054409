import React, { useState } from "react";
import styled from "@emotion/styled";
import Logo from "../assets/logo.svg";
import useDatabaseRecipes from "../hooks/useDatabaseRecipes";
import RecipesLoading from "../components/recipes/RecipesLoading";
import Footer from "../components/Footer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const options = ["Share", "Copy link"];

const ITEM_HEIGHT = 48;

export default function CreatorsProfile() {
  const { fetchedRecipes } = useDatabaseRecipes("gptRecipes", 25);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledProfile>
        <div className="main_profile">
          <div className="image_container">
            <div className="border_image">
              <img src={Logo} alt="logo" />
            </div>
            <div className="username">
              <h5>@bibi</h5>
            </div>
          </div>
          <div className="profile_details">
            <div className="recipes">
              <h4>25</h4>
              <p>Recipes</p>
            </div>
            <div className="followers">
              <h4>100K</h4>
              <p>Followers</p>
            </div>
            <div className="likes">
              <h4>380K</h4>
              <p>Likes</p>
            </div>
          </div>
          <div className="buttons_container">
            <div>
              <button className="secondary-btn">Follow</button>
            </div>

            <div>
              <MoreVertIcon
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option} onClick={handleClose}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <div className="recipe-data">
            <RecipesLoading data={fetchedRecipes} />
          </div>
        </div>
      </StyledProfile>
      <Footer />
    </>
  );
}

const StyledProfile = styled.div`
  margin-top: var(--section-margin);
  .main_profile {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
    .image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .border_image {
        width: 100px;
        height: 100px;
        background-color: var(--primary-light-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70%;
          height: auto;
          border-radius: 50%;
        }
      }
    }
    .username {
      h5 {
        font-weight: 600;
      }
    }
    .profile_details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      text-align: center;
      /* .recipes,
      .followers,
      .likes {
        text-align: center;
      } */
      h4 {
        font-weight: bolder;
      }
    }
    .buttons_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
`;
