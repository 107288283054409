import React from "react";
import styled from "styled-components";
import plate from "../assets/error-plate.png";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function ErrorPage() {
  return (
    <>
      <Nav />
      <StyledError>
        <h1>
          4
          <span>
            <img src={plate} alt="plate" />
          </span>
          4
        </h1>
        <h3>Page not found</h3>
      </StyledError>
      <Footer />
    </>
  );
}

const StyledError = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 240px;
  }
  h3{
    color: var(--secondary-dark-color);
  }
  img {
    max-width: 240px;
  }
  @media (max-width: 600px) {
    h1{
      font-size: 140px;
    }
    img{
      max-width: 140px;
    }
  }
`;

export default ErrorPage;
