import styled from "styled-components";
import React, { useState } from "react";
import LoadingButton from "../LoadingButton";
import demoImg from "../../assets/demoImg.svg";
import GroupIcon from "@mui/icons-material/Group";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import useAddRecipe from "../../hooks/useAddRecipe";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router";

function QuickAddRecipe({
  handleBackdrop,
  data,
  selectedServings,
}) {
  data = Object.assign({}, data);
  const [loading, setLoading] = useState(false);
  const {date: selectedDate} = useParams()
  const [formValues, setFormValues] = useState({
    mealType: "breakfast",
    date: selectedDate || moment(new Date()).format("YYYY-MM-DD"),
    servings: selectedServings || data.servings,
  });

  const addRecipeHandler = useAddRecipe();

  async function submitHandler(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await addRecipeHandler(
        formValues.date,
        data,
        formValues.mealType,
        formValues.servings
      );
      handleBackdrop(false);
    } catch (e) {
      setLoading(false);
      console.log("e", e);
    }
  }
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <StyledRecipe>
      <div className="heading">
        <h4>{data?.title}</h4>
        <button className="icon" onClick={(e) => handleBackdrop(false)}>
          {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
        </button>
      </div>
      <div className="content">
        <div className="info">
          <img src={data?.image || demoImg} alt="" />
          <div className="description">
            <h5>Details</h5>
            <div className="details">
              <div className="size">
                <GroupIcon fontSize="medium" htmlColor="#F79C16" />
                <p>{formValues.servings}</p>
              </div>
              <div className="time">
                <AvTimerIcon fontSize="medium" htmlColor="#F79C16" />
                <p>{data.readyInMinutes}min</p>
              </div>
              <div className="meal-type">
                <RestaurantIcon fontSize="medium" htmlColor="#F79C16" />
                <p>
                  {data?.dishType !== undefined
                    ? data.dishType
                    : data.dishTypes[0]}
                </p>
              </div>
              {data.healthScore && (
                <div className="health-score">
                  <MonitorHeartIcon fontSize="medium" htmlColor="#F79C16" />
                  <p>{parseInt(data.healthScore) / 10}/10</p>
                </div>
              )}
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.summary?.substring(0, 500),
              }}
            />
          </div>
        </div>
        <form onSubmit={submitHandler} className="settings">
          <div className="radio-inputs">
            <h5>Select time of day*</h5>
            <label htmlFor="breakfast">
              <input
                type="radio"
                name="mealType"
                value="breakfast"
                id="breakfast"
                defaultChecked
                required
                onChange={handleChange}
              />
              <p>Breakfast</p>
            </label>
            <label htmlFor="lunch">
              <input
                type="radio"
                name="mealType"
                value="lunch"
                id="lunch"
                required
                onChange={handleChange}
              />
              <p>Lunch</p>
            </label>
            <label htmlFor="dinner">
              <input
                type="radio"
                name="mealType"
                value="dinner"
                id="dinner"
                required
                onChange={handleChange}
              />
              <p>Dinner</p>
            </label>
            <label htmlFor="snack">
              <input
                type="radio"
                name="mealType"
                value="snack"
                id="snack"
                required
                onChange={handleChange}
              />
              <p>Snack</p>
            </label>
          </div>
          <label className="input">
            <h5>Servings*</h5>
            <input
              type="number"
              min="1"
              max="100"
              step="1"
              name="servings"
              value={formValues.servings}
              onChange={handleChange}
            />
          </label>
          <label className="input">
            <h5>Date*</h5>
            <input
              type="date"
              value={formValues.date}
              onChange={handleChange}
              name="date"
              required
              placeholder="DD/MM/YYYY"
            />
          </label>
          <div className="btn">
            <LoadingButton loading={loading} title="Add to Calendar" type="submit" />
          </div>
        </form>
      </div>
    </StyledRecipe>
  );
}

const StyledRecipe = styled.div`
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .content {
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .info {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      img {
        min-width: 200px;
        max-width: 320px;
        border-radius: var(--radius);
      }
      .description {
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
          max-width: 45ch;
        }
        .details {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 6px;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            p {
              font-weight: bold;
            }
          }
        }
      }
    }
    .settings {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .radio-inputs {
        label {
          display: flex;
          /* flex-direction: column; */
          /* justify-content: center; */
          align-items: center;
          gap: 10px;
          p {
            display: inline-block;
          }
          input {
            height: 30px;
          }
        }
      }
      .input {
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: 4px;
        }
        input {
          width: 180px;
          height: 36px;
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 500px) {
    .content {
      .info {
        flex-wrap: wrap;
        img {
          min-width: 100px;
          max-width: 160px;
        }
      }
    }
  }
`;

export default QuickAddRecipe;
