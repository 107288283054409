import DashboardHome from "../components/dashboard/DashboardHome";
import StockandHistory from "../components/dashboard/StockandHistory";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import DashboardRecipes from "../components/dashboard/DashboardRecipes";

function Dashboard() {

  return (
    <>
      <StyledDashboard>
        <Nav />
        <DashboardHome />
        <StockandHistory />
        <DashboardRecipes />
      </StyledDashboard>
      <Footer />
    </>
  );
}

const StyledDashboard = styled.div`
  width: 100vw;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export default Dashboard;
