import React, { useState } from "react";
import styled from "styled-components";
import BackdropWrapper from "../modals/BackdropWrapper";
import ConfirmCartModal from "../modals/ConfirmCartModal";
import { HiOutlineShoppingBag } from "react-icons/hi2";

export default function CartSummary({ data }) {
  const [backdrop, setBackdrop] = useState(false);
  let checkedRecipes = [];
  Object.values(data).forEach((dateData) => {
    const recipes = Object.values(dateData.items);
    if (dateData.checked) {
      checkedRecipes = [...checkedRecipes, ...recipes];
    } else {
      recipes.forEach((item) => item.checked && checkedRecipes.push(item));
    }
  });
  function backdropHandler() {
    setBackdrop((current) => !current);
  }


  return (
    <>
      <BackdropWrapper
        handleBackdrop={backdropHandler}
        element={
          <ConfirmCartModal
            backdropHandler={backdropHandler}
            checkedRecipes={checkedRecipes}
          />
        }
        open={backdrop}
      />
      <StyledSummary>
        <h4>Order Summary</h4>
        <hr />
        <div className="main_container">
          {checkedRecipes.length > 0 ? (
            <ul>
              {checkedRecipes.map((recipe, index) => (
                <li key={recipe.id}>
                  {index + 1}. {recipe.data.title}
                </li>
              ))}
            </ul>
          ) : (
            <p className="empty-message">No items selected.</p>
          )}
        </div>
        <hr />
        <button
          disabled={checkedRecipes.length === 0}
          className="review-btn"
          onClick={backdropHandler}
        >
          {/* <HiOutlineShoppingBag /> */}
          Review Cart
        </button>
      </StyledSummary>
    </>
  );
}

const StyledSummary = styled.div`
  width: 100%;
  margin: auto;
  border: 1px solid var(--gray-color);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 15px;

  h4 {
    padding: 15px 0 0 0;
  }

  hr {
    width: 90%;
    margin: auto;
    border: 0;
    border-top: 1px solid var(--gray-color);
  }

  .main_container {
    ul {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      li {
        text-align: center;
      }
    }

    .empty-message {
      font-style: italic;
      color: var(--gray-color);
      text-align: center;
    }
  }
  .review-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }
`;
