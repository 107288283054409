import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InstacartIngredientBtn from "../cart/InstacartIngredientBtn";

export default function ConfirmCartModal({ backdropHandler, checkedRecipes }) {
  const [checkedIngredients, setCheckedIngredients] = useState([]);

  function extractCheckedIngredients(checkedRecipes) {
    let finalIngreients = [];
    checkedRecipes.forEach((recipe) => {
      recipe.ingredients.data.forEach((ingredient) => {
        if (ingredient.checked) {
          const ingredientIndex = finalIngreients.findIndex(
            (value) =>
              value.nameClean?.toLowerCase() ===
              ingredient.nameClean?.toLowerCase()
          );
          console.log("ing index", ingredientIndex);
          if (ingredientIndex !== -1) {
            finalIngreients[ingredientIndex].measures.us.amount +=
              ingredient.measures.us.amount;
          } else {
            finalIngreients.push(ingredient);
          }
        }
      });
    });
    return finalIngreients;
  }

  const ingredientsAmountHandler = (index, value) => {
    setCheckedIngredients((current) => {
      const updatedIngredients = [...current];
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        measures: {
          ...updatedIngredients[index].measures,
          us: {
            ...updatedIngredients[index].measures.us,
            amount: updatedIngredients[index].measures.us.amount + value,
          },
        },
      };
      console.log("curr", updatedIngredients[index].measures.us.amount);
      return updatedIngredients;
    });
  };

  const ingredientCheckedHandler = (index, isChecked) => {
    setCheckedIngredients((current) => {
      const updatedIngredients = [...current];
      updatedIngredients[index].checked = isChecked;
      return updatedIngredients;
    });
  };


  console.log("Checked Ingredients:", checkedIngredients);

  useEffect(() => {
    console.log("in use");
    setCheckedIngredients(extractCheckedIngredients(checkedRecipes));
  }, []);

  return (
    <>
      <StyledCartModal>
        <div className="heading">
          <h4>Confirm Cart</h4>
          <button className="icon" onClick={(e) => backdropHandler(false)}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        </div>

        <div className="confirm-cart">
          <div className="main_container">
            {checkedIngredients?.map((ingredient, index) => (
              <div className="ingredient" key={index}>
                <div className="indgredient_cotainer">
                  <input
                    checked={ingredient.checked}
                    onChange={(e) =>
                      ingredientCheckedHandler(index, e.target.checked)
                    }
                    type="checkbox"
                    id={`ingredient-${index}`}
                  />
                  <label
                    className="ingredients"
                    htmlFor={`ingredient-${index}`}
                  >
                    {ingredient.nameClean}
                  </label>
                </div>

                <div className="serving-btn">
                  <div className="buttons">
                    <button
                      className="add-btn"
                      onClick={() => ingredientsAmountHandler(index, +1)}
                    >
                      <RemoveIcon />
                    </button>
                    <div className="serving-number">
                      {/* <input defaultValue={ingredient.measures?.us?.amount} /> */}
                      <span>
                        {checkedIngredients[index].measures?.us?.amount}{" "}
                        {ingredient.measures?.us?.unitShort}
                      </span>
                    </div>
                    <button
                      className="add-btn"
                      onClick={(e) => ingredientsAmountHandler(index, 1)}
                    >
                      <AddIcon />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <InstacartIngredientBtn selectedItems={checkedIngredients} ingredientMeasures={"us"} />
        </div>
      </StyledCartModal>
    </>
  );
}

const StyledCartModal = styled.div`
  p {
    text-align: center;
    max-width: 35ch;
    margin: auto;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .confirm-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    .main_container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      padding-bottom: 2%;

      .ingredient {
        width: calc(50% - 0.5rem);
        min-height: 84px;
        border: 1px solid gray;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        box-sizing: border-box;

        .indgredient_cotainer {
          display: flex;
          align-items: center;
          background-color: #fbfbfb;
          padding: 4% 4%;
          border-radius: 10px;

          input {
            margin-right: 1rem;
          }
          label {
            color: black;
          }
        }

        .serving-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;
          .serving-number {
            white-space: nowrap;
            /* display: flex;
            align-items: center;
            justify-content: center; */
            /* input {
              width: 20px;
              max-width: 60px;
              height: 26px;
              padding: 0px;
              border-radius: 4px;
              border-width: 0px;
            } */
          }
          .buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            width: 120px;
            height: 40px;
            /* border: 1px solid var(--shadow); */
            border-radius: 48px;
            background-color: #fff;

            .add-btn,
            .minus-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 30px;
              max-width: 30px;
              height: 30px;
              background-color: transparent;
              /* border: 1px solid var(--shadow); */
              border-radius: 4px;
              color: var(--secondary-dark-color);
              font-weight: 600;
              font-size: 1.2rem;
              cursor: pointer;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .no-ingredients-message {
      text-align: center;
      color: #888;
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 640px) {
    .confirm-cart {
      .main_container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .ingredient {
          width: 100%;
          flex-direction: column;
          gap: 1rem;
          padding-top: 5px;
          padding-bottom: 5px;
          .indgredient_cotainer {
            width: 100%;
          }
        }
      }
    }
  }
`;
