import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import firebaseApi from "../../utils/firebaseApi";
import { alertActions } from "../../store/alertSlice";
import LoadingButton from "../LoadingButton";
import { MdOutlineMail } from "react-icons/md";

export default function CartToEmail() {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.user);

  const emailHandler = async () => {
    setLoading(true); 
    try {
      const url = `/sendCartToEmail?uid=${uid}&email=${inputValue}`;
      const res = await firebaseApi.get(url);
      console.log("res", res.data);
      dispatch(
        alertActions.setAlert({
          title: "Email sent successfully",
          messageType: "success",
        })
      );
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to send email",
          messageType: "error",
        })
      );
      console.log("error", e);
    } finally {
      setLoading(false); 
    }
  };
  return (
    <>
      <StyledCartEmail>
        <h4>Send shopping cart items to an email.</h4>

        <hr />

        <div className="main_container">
          <input
            type="text"
            placeholder="Enter your email"
            value={inputValue}
            minLength={3}
            required
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>

        <LoadingButton
          className="email-btn"
          onClick={emailHandler}
          loading={loading}
          
          title={
            <>
              <MdOutlineMail style={{ marginRight: '8px' }} /> 
              Send Email
            </>
          }
        />
      </StyledCartEmail>
    </>
  );
}

const StyledCartEmail = styled.div`
  width: 100%;
  margin: auto;
  border: 1px solid var(--gray-color);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 15px;

  h4 {
    padding: 15px 0 0 0;
    text-align: center;
  }

  hr {
    width: 90%;
    margin: auto;
    border: 0;
    border-top: 1px solid var(--gray-color);
  }

    
  .email-btn{
    background-color: var(--green-color);
  }

 

  
`;
