import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function LoadingCircle(props) {
  return (
    <StyledCircular {...props}>
      <CircularProgress size={24} sx={{ color: "var(--primary-color)" }} />
    </StyledCircular>
  );
}

const StyledCircular = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
