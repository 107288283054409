import CartRecipes from "./CartRecipes";
import styled from "styled-components";
import toggleSelection from "./cartfunction.js";
import CartSummary from "./CartSummary.js";
import CartToEmail from "./CartToEmail.js";
import CartHeader from "./CartHeader.js";
import { useLocation, useNavigate } from "react-router";

let currentHeading = "";
export default function Cart({ cartData, setCartData, reviewCartHandler }) {
  const urlPath = useLocation().pathname;
  const navigate = useNavigate();
  // console.log("data", cartData);
  return (
    <StyledCart>
      <div className="header">
        <h3>{urlPath === "/cart" ? "My Cart" : "Cart History"}</h3>
        {/* {urlPath === "/cart" && (
          <button className="secondary-btn" onClick={() => navigate("history")}>
            Cart History
          </button>
        )} */}
      </div>
      <div className="main_cart">
        <div className="my-cart">
          {Object.entries(cartData).map(([headingId, data], index) => {
            currentHeading = headingId;
            return (
              <>
                <CartHeader
                  headingId={headingId}
                  toggleSelection={toggleSelection}
                  setCartData={setCartData}
                />
                <hr className="divider" />
                <CartRecipes
                  data={data.items}
                  headingId={headingId}
                  toggleSelection={toggleSelection}
                  setCartData={setCartData}
                />
              </>
            );
          })}
        </div>

        <div className="cart-summary">
          <CartSummary data={cartData} />
          <CartToEmail />
        </div>
      </div>
      <div className="cart_bar">
        <div className="cart_items">
          <h4>Cart Items:</h4>
          <p>5</p>
        </div>
        <button onClick={reviewCartHandler} aria-label="Review Cart">
          Review Cart
        </button>
      </div>
    </StyledCart>
  );
}

const StyledCart = styled.div`
  width: 90%;
  margin: 0 auto var(--section-margin) auto;
  padding-bottom: 3rem;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart_bar {
    display: none;
  }

  /* .cart_bar{
   width: 90%;
   margin: auto;
   height: 60px;
   border: 1px solid var(--gray-color);
   border-radius: 24px;
   margin-top: 15px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 8px 0 8px;

   .cart_items{
    display: flex;
   justify-content: center;
   align-items: center;
   gap: 1rem;

   }
   
  } */

  h3 {
    margin-bottom: var(--heading-margin-sm);
    margin-top: var(--heading-margin-sm);
  }

  .main_cart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    .my-cart {
      flex-basis: 58%;
      border: 1px solid gray;
      border-radius: 24px;
      height: 65vh;
      overflow-y: auto;
      /* display: flex;
      justify-content: center;
      align-items: center; */
      /* flex-direction: column; */
      gap: 1rem;
      .divider {
        width: 95%;
        margin: auto;
        border-color: 0.1rem solid var(--shadow-light);
      }
    }

    .cart-summary {
      flex-basis: 38%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }

  @media (max-width: 1025px) {
    .cart_bar {
      width: 100%;
      margin: auto;
      height: 60px;
      border: 1px solid var(--gray-color);
      border-radius: 24px;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px 0 8px;

      .cart_items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
    }
    .main_cart {
      .my-cart {
        flex-basis: 100%;
      }
      .cart-summary {
        display: none;
      }
    }
  }
`;
