import React from "react";
import { useState } from "react";
import styled from "styled-components";
import LoadingButton from "../LoadingButton";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { useSelector } from "react-redux";
import TypewriterBar from "./TypewriterBar";
import useGenerateImg from "../../hooks/useGenerateImg";
import { searchAlgolia } from "../../utils/algoliaFunctions";
import {
  titlesWithIngredientsFormat,
  recipeShortFormat,
  recipeLongFormat,
} from "../../utils/gptFormats";
import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
const gptModel = "gpt-4o";
// "gpt-3.5-turbo-1106";

let generatedImages = {};
let placeholdersArray = [
  "Quick and easy dinner recipe",
  "Healthy vegetarian lunch recipe for",
  "Low-carb breakfast recipe for",
  "Delicious vegan dessert recipe",
  "10-minute appetizer recipe",
  "Hearty soup recipe for a family of five",
  "Kid-friendly snack recipe",
  "Low-calorie salad recipe for weight loss",
  "Spicy Thai dinner recipe",
  "Authentic Mexican meal recipe",
  "Sugar-free dessert recipe for diabetics",
  "Japanese sushi recipe for a party",
  "Paleo-friendly lunch recipe",
  "Keto dinner recipe for a weeknight",
  "Middle Eastern mezze recipe for a potluck",
  "Vegan pizza recipe for a movie night",
  "5-ingredient pasta recipe for college students",
  "Nut-free dessert recipe for those with allergies",
  "Traditional Irish stew recipe for St. Patrick's Day",
  "Classic French dessert recipe for a dinner party",
  "Soul food recipe for a Sunday dinner",
  "Spanish tapas recipe for entertaining",
  "Greek salad recipe for a healthy lunch",
  "Caribbean jerk chicken recipe for a summer BBQ",
  "Dairy-free lasagna recipe for those with lactose intolerance",
  "Swedish meatball recipe for a cozy dinner",
  "Dutch pancake recipe for a brunch",
  "Korean bibimbap recipe for a rice bowl",
  "Turkish baklava recipe for a delicious dessert",
  "Icelandic skyr dessert recipe for a healthy treat",
  "One-pot dinner recipe for a busy night",
  "Nutritious smoothie recipe for breakfast",
  "Delectable seafood dish for a romantic dinner",
  "No-bake dessert recipe for a quick treat",
  "Indian curry recipe for a spicy meal",
  "Gluten-free bread recipe for a healthy option",
  "French quiche recipe for a savory brunch",
  "Southwestern-style salad for a light lunch",
  "Mediterranean dip recipe for a party appetizer",
  "Whole grain pasta dish for a family dinner",
  "Refreshing summer cocktail recipe",
  "Homemade ice cream recipe for a cool dessert",
  "Grilled vegetable recipe for a healthy side",
  "Slow cooker chili recipe for an easy meal",
  "Comforting casserole recipe for a potluck",
  "Stuffed mushroom recipe for a flavorful appetizer",
  "Seasonal fruit dessert recipe for a fresh treat",
  "High-protein breakfast recipe for energy",
  "Colorful grain bowl recipe for a balanced lunch",
  "Hearty stew recipe for a winter meal",
  "Indian paneer tikka recipe for a vegetarian starter",
];
function GptSearch({
  newRecipe,
  setNewRecipe,
  setUserRecipes,
  setNewRecipeTitles,
}) {
  const [input, setInput] = useState({
    query: "",
    noOfServings: 1,
    dietRestriction: "none",
  });
  const uid = useSelector((state) => state.user.uid);
  const imageGeneration = useGenerateImg();

  async function gptResponse(recipeName, recipeIngredients, recipeSteps) {
    return await openai.createChatCompletion({
      model: gptModel,
      response_format: { type: "json_object" },
      // prompt: `${input.query} recipe with ${input.noOfServings} servings ${
      //   input.dietRestriction === "none"
      //     ? ""
      //     : `which should follow ${input.dietRestriction} diet`
      // }, please don't provide any explanation, just provide the output. It should include all the ingredients and steps. Only respond to queries about recipes, otherwise throw an error. Only generate one recipe from the given input. Output should be a json object using the following format: ${gptPrompt}`,
      messages: [
        {
          role: "system",
          content: `Only respond to queries about recipes, otherwise throw an error. Only generate one recipe from the given input. Output should only be in json format, no extra words. Fill all the parameters in the following javascript object: ${recipeShortFormat}`,
        },
        {
          role: "user",
          content: `Provide more information about ${recipeName} recipe having following ingredients: ${recipeIngredients} and following cooking instructions: ${recipeSteps} in json format. Please don't provide any explanation, just provide the output. It should include all the ingredients and steps. Please use only the provide title in the title field. Provide optional information of sides that goes with the recipe in the steps and ingredients`,
        },
      ],
      temperature: 1,
    });
  }
  async function gptResponse2(recipeName, recipeIngredients, recipeSteps) {
    return await openai.createChatCompletion({
      model: gptModel,
      response_format: { type: "json_object" },
      messages: [
        {
          role: "system",
          content: `Only respond to queries about recipes, otherwise throw an error. Only generate one recipe from the given input. Output should only be in json format, no extra words. Use the following fields: ${recipeLongFormat}`,
        },
        {
          role: "user",
          content: `Provide more information about ${recipeName} recipe having following ingredients: ${recipeIngredients} and following cooking instructions: ${recipeSteps} in json format.`,
        },
      ],
      temperature: 1,
      // max_tokens: 3400,
      // stream: true,
    });
  }
  async function alternativeRecipes() {
    const namesRes = await openai.createChatCompletion({
      model: gptModel,
      messages: [
        {
          role: "system",
          content: `Only respond to queries about recipes, otherwise throw an error. Don't provide controversial recipes. Output should be an array of recipes titles`,
        },
        {
          role: "user",
          content: `${input.query} recipe 
          and provide only 3 more recipes not less than 3 or more than 3 that are similar to the input, please don't provide any explanation, just provide recipes title as an array`,
        },
      ],
      temperature: 1,
    });
    return JSON.parse(namesRes.data.choices[0].message.content);
  }
  async function recipesWithIngredients(recipeNames) {
    const ingredientsRes = await openai.createChatCompletion({
      model: gptModel,
      response_format: { type: "json_object" },
      messages: [
        {
          role: "system",
          content: `Only respond to queries about recipes, otherwise throw an error. Don't provide controversial recipes. Output should be a json object using the following format: ${titlesWithIngredientsFormat}`,
        },
        {
          role: "user",
          content: `provide ingredients and cooking instructions(steps) of the following recipes: ${recipeNames}. Please don't provide any explanation, just provide recipes title with steps and ingredients in json format.`,
        },
      ],
      temperature: 1,
    });
    return JSON.parse(ingredientsRes.data.choices[0].message.content);
  }
  async function generateRecipeImage(title, ingredients, steps, id) {
    console.log("title", title, ingredients, steps, id);
    try {
      const image = await imageGeneration(title, ingredients, steps, id);
      generatedImages = { ...generatedImages, [id]: image };
    } catch (error) {
      console.error("Error while generating image:", error);
      generatedImages = { ...generatedImages, [id]: null };
    }
  }

  const generateRecipe = async () => {
    try {
      const recipesNames = await alternativeRecipes();
      // const recipesNames = JSON.parse(namesRes.data.choices[0].message.content);

      setNewRecipeTitles(
        recipesNames.reduce(
          (accumulator, name) => ({ ...accumulator, [name]: [] }),
          {}
        )
      );

      let generatedRecipes = await recipesWithIngredients(recipesNames);

      console.log("short recipes", generatedRecipes);

      setNewRecipeTitles(generatedRecipes);

      const currentDate = new Date().toISOString();

      Object.entries(generatedRecipes).forEach(
        async ([name, { ingredients, steps }]) => {
          let id = Math.random().toString(16).slice(2);
          generateRecipeImage(name, ingredients, steps, id);

          let shortRecipe = {};
          gptResponse(
            name,
            JSON.stringify(ingredients),
            JSON.stringify(steps)
          ).then((res1) => {
            const response = JSON.parse(res1.data.choices[0].message.content);
            const image = generatedImages[id];
            shortRecipe = {
              ...response,
              id,
              uid,
              image: image,
              imageGenerating: image ? false : true,
              title: name,
              ingredients,
              steps
            };

            setNewRecipe((current) => ({
              data: [...current.data, shortRecipe],
              loading: false,
              error: "",
            }));
          });

          const res2 = await gptResponse2(
            name,
            JSON.stringify(ingredients),
            JSON.stringify(steps)
          );
          let longRecipe = JSON.parse(res2.data.choices[0].message.content);
          const image = generatedImages[id];

          longRecipe = {
            ...longRecipe,
            ...shortRecipe, //shortRecipe should deconstruct after longRecipe to apply it's properties
            image,
            id,
            imageGenerating: false,
            generationDate: currentDate,
            recipeType: "gpt",
            recipeSource: "gpt",
          };

          setNewRecipe((current) => {
            const filtered = current.data.filter((rec) => rec.id !== id);
            const recipes = [longRecipe, ...filtered];
            console.log("final recipes", recipes);
            setUserRecipes((current) => ({
              ...current,
              data: [longRecipe, ...current.data],
            }));
            return {
              data: recipes,
              loading: false,
              error: "",
            };
          });

          window.dataLayer.push({
            event: "recipe-generated",
            recipeId: longRecipe.id,
          });
          const docRef = doc(db, "gptRecipes", id);
          const res = await setDoc(docRef, longRecipe);
          console.log("res 2", res);
        }
      );
    } catch (e) {
      console.log("e", e);
      setNewRecipe((current) => ({
        ...current,
        loading: false,
        errorMsg: "Please provide specific details and generate again.",
      }));
    }
  };

  async function inputSubmitHandler(e) {
    e.preventDefault();
    try {
      setNewRecipeTitles({});
      setNewRecipe((current) => ({ data: [], loading: true }));
      let algoliaHits = await searchAlgolia({ query: input.query });
      console.log("algolia hits", algoliaHits);
      if (algoliaHits?.length > 0) {
        setNewRecipe({ data: algoliaHits, loading: false });
      } else {
        await generateRecipe();
      }
    } catch (e) {
      console.log("gpt error", e);
      setNewRecipe({
        data: [],
        loading: false,
        errorMsg: "Please provide specific details and generate again.",
      });
    }
  }

  function inputChangeHandler(e) {
    setInput((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  }
  return (
    <>
      <h4 style={{ textAlign: "center", color: "var(--secondary-dark-color)" }}>
        Generate your own recipes using AI
      </h4>
      <StyledSearch onSubmit={inputSubmitHandler}>
        <textarea
          required
          value={input.query}
          name="query"
          onChange={inputChangeHandler}
          placeholder={`// Generate custom recipes, e.g.,\n${
            placeholdersArray[
              Math.floor(Math.random() * (placeholdersArray.length - 1)) + 1
            ]
          }`}
        />
        {/* <div className="filters">
          <label>
            <span>Servings</span>
            <input
              value={input.noOfServings}
              name="noOfServings"
              onChange={inputChangeHandler}
              type="number"
              // defaultValue={1}
              min={1}
              max={25}
            />
          </label>
          <label>
            <span>Diet Restriction</span>
            <select
              value={input.dietRestriction}
              name="dietRestriction"
              onChange={inputChangeHandler}
              // defaultValue="none"
            >
              <option value="none">None</option>
              {profilePreferenceItems.diet_preferences.values.map(
                (value, index) => (
                  <option
                    key={index}
                    value={value.toLowerCase().replace(/ /g, "-")}
                  >
                    {value}
                  </option>
                )
              )}
            </select>
          </label>
        </div> */}
        {newRecipe?.loading ? (
          <TypewriterBar />
        ) : (
          <div className="extra-height" />
        )}
        <LoadingButton
          loading={newRecipe?.loading}
          type="submit"
          title="Generate"
        />
      </StyledSearch>
    </>
  );
}

const StyledSearch = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  gap: 6px;
  margin: 20px auto;
  h4 {
    text-align: center;
  }
  textarea {
    height: 100px;
    min-width: 520px;
    max-width: 600px;
    max-height: 140px;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 50px;
  }
  .extra-height {
    height: 47.15px;
  }
  .filters {
    display: flex;
    gap: 2rem;
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      input,
      select {
        border-radius: 4px;
      }
      input[type="number"] {
        width: 70px;
        height: 38.4px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    textarea {
      min-width: 300px;
      max-width: 400px;
    }
  }
`;

export default GptSearch;
