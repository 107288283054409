import styled from "styled-components";
import React from "react";
import { useNavigate, useParams } from "react-router";
// import { useSearchParams } from "react-router-dom";
import ProfileTabs from "../components/profile-settings/ProfileTabs";
import {
  profileDescriptionItems,
  profileTabItems,
} from "../utils/profilePageData";
import Nav from "../components/Nav";
import ProfileItems from "../components/profile-settings/ProfileItems";

function ProfileSetup() {
  const { id } = useParams();
  const navigate = useNavigate();

  let RenderComponent;
  let index = 0;
  if (id === "preferences") {
    RenderComponent = <ProfileItems />;
  } else if (id === "pantry") {
    index = 1;
    RenderComponent = <ProfileTabs editable={true} tabData={profileTabItems} />;
  }
  return (
    <>
      <Nav />
      <StyledProfileSetup>
        <div className="text">
          <div>
            <h3>{profileDescriptionItems[index]?.title}</h3>
            <button
              onClick={(e) =>
                
                id === "pantry"
                  ? navigate("/dashboard")
                  : navigate("/profile-setup/pantry")
              }
            >
              Skip to next step
            </button>
            <h5>{index + 1}/2</h5>
          </div>
          {/* <p>{profileDescriptionItems[index]?.description}</p> */}
          {/* <p>{profileDescriptionItems[index]?.note}</p> */}
        </div>
        {RenderComponent}
      </StyledProfileSetup>
    </>
  );
}

const StyledProfileSetup = styled.div`
  width: var(--laptop-width);
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  gap: 60px;
  h4 {
    font-weight: 500;
  }
  .text {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 10px auto;
      h3 {
        flex: 1 1 75%;
      }
    }
    p {
      line-height: 1.8;
    }
  }
`;

export default ProfileSetup;
