import { createSlice } from "@reduxjs/toolkit"

const modal = createSlice({
    name: "modal",
    initialState: {loginModalActive: false},
    reducers: {
        setLoginModalActive(state, action) {
            state.loginModalActive = action.payload
        },
    }

})

export const modalActions = modal.actions;
export const modalReducer = modal.reducer;