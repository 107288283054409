import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";

export default function LandingAI() {
  const videoRef = useRef(null);
  const scrollSectionRef = useRef(null);
  // const [hasLoaded, setLoaded] = useState(false);
  const [elementVisible, setElementVisible] = useState(false);
  // const [lastScrollTop, setLastScrollTop] = useState(0); // State to store the last scroll position

  const mediaQuery = useMediaQuery("(max-width: 700px)");

  const navigate = useNavigate();

  useEffect(() => {
    const playbackConst = 500; // Adjust the constant as needed
    // Use requestAnimationFrame for smooth playback
    function scrollPlay() {
      if (videoRef.current) {
        const frameNumber = window.pageYOffset / playbackConst;
        videoRef.current.currentTime = frameNumber;
      }
      window.requestAnimationFrame(scrollPlay);
    }

    window.requestAnimationFrame(scrollPlay);
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    video?.addEventListener("loadedmetadata", () => {
      const { duration } = video;
      const playbackConst = 500;
      const scrollSection = scrollSectionRef.current;
      console.log("scrollSection", videoRef.current.duration);
      console.log(duration); // Output: video duration in seconds
    });

    const onVideoPlay = () => {
      console.log("Video started playing");
      // Additional logic when video starts
      setElementVisible(true);
      const playbackConst = 500;

      const scrollSection = scrollSectionRef.current;
      if (videoRef?.current) {
        scrollSection.style.height =
          Math.floor(videoRef.current.duration) * playbackConst + "px";
      }

      video?.addEventListener("ended", onVideoEnd);
    };

    const onVideoEnd = () => {
      console.log("Video ended", elementVisible);
      // Additional logic when video ends
      setElementVisible(false);
      // scrollSectionRef.current.style.height = "auto";
      // Remove the 'ended' event listener so it doesn't trigger again
      video?.removeEventListener("ended", onVideoEnd);
    };

    // Add event listeners
    video?.addEventListener("play", onVideoPlay);
    video?.addEventListener("ended", onVideoEnd);

    return () => {
      video?.removeEventListener("loadedmetadata", () => {});
      // Remove event listeners
      video?.removeEventListener("play", onVideoPlay);
      video?.removeEventListener("ended", onVideoEnd);
    };
  }, []);

  console.log("isvisible", elementVisible);
  return (
    <Element name="ai">
      <div style={{ position: "relative" }} ref={scrollSectionRef}>
        <StyledAI
          elementVisible={elementVisible}
          ref={scrollSectionRef}
          id="video-scroll"
        >
          {/* <img src={tomatoLeaf} alt="break bread" /> */}
          {/* {mediaQuery ? ( */}
          <h3>AI Recipes</h3>
          <div className="content">
            <div className="text">
              <h4>
                Generate <span>custom recipes</span> using our AI
              </h4>
              <p>
                Generate recipes using our top notch AI feature based on your
                cuisine or diet preferences. Choose any diet plan and keep on
                track of your goals. Our AI can generate recipe based on
                ingredients available in your pantry.
              </p>
              <button
                onClick={(e) =>
                  // navigate("/login", { state: { from: "/dashboard/ai-recipes" } })
                  navigate("/dashboard/ai-recipes")
                }
                className="try-now-gtag"
              >
                Try Now
              </button>
            </div>
            <div className="video">
              <video autoPlay muted loop playsInline controls={false}>
                <source src="https://firebasestorage.googleapis.com/v0/b/break-bread-prod.appspot.com/o/web-app-assets%2Ffirst-v-ai-section-video.mp4?alt=media&token=72e49637-a994-44cb-9b82-bc41b2038eba" />
              </video>
            </div>
          </div>
          {/* ) : (
            <>
              <motion.h3
                initial={{ opacity: 0, y: 16 }}
                whileInView={{ opacity: 100, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", delay: 0.3, duration: 0.4 }}
              >
                AI Recipes
              </motion.h3>
              <motion.div
                initial={{ opacity: 0, y: 16 }}
                whileInView={{ opacity: 100, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", delay: 0.3, duration: 0.4 }}
                className="content"
              >
                <video ref={videoRef} autoPlay={true} muted>
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/break-bread-prod.appspot.com/o/web-app-assets%2Fai-recipe-generation.mp4?alt=media&token=d7985a26-fe46-42c4-b79a-bd9d7bd4bb4e"
                    type="video/mp4"
                  />
                </video>
              </motion.div>
            </>
          )} */}
        </StyledAI>
      </div>
    </Element>
  );
}

const StyledAI = styled.section`
  padding-top: var(--section-margin);
  /* position: relative; */
  position: sticky;
  top: 10%;
  #scroll-section {
    display: block;
  }

  img {
    position: absolute;
    width: 80px;
    top: 25%;
    left: 0%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    z-index: -1;
  }
  h3{
    text-align: center;
    margin-bottom: var(--section-margin);
  }
  h5 {
    text-align: center;
    margin: 1rem auto;
    color: var(--secondary-dark-color);
  }

  .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20%;
    margin-bottom: var(--section-margin);
    .text {
      margin-bottom: 10%;
      justify-self: center;
      h4 {
        span {
          color: var(--accent-color);
          font-size: inherit;
          font-weight: inherit;
        }
      }
      p {
        margin: 8px 0;
        max-width: 40ch;
      }
    }
    .video {
      box-shadow: 0 0 4px 4px lightgray;
      border-radius: 24px;
      overflow: hidden;
      video {
        width: 100%;
        outline: 0px;
        clip-path: inset(1px 1px);
      }
    }
  }
  @media (max-width: 500px) {
    top: 5%;
    .content .video {
      width: 95%;
    }
  }
  @media (max-width: 700px) {
    top: 0;
    img {
      width: 40px;
    }
    .content {
      justify-content: center;
      .text {
        text-align: center;
        p {
          margin: 8px auto;
        }
      }
      video {
        width: 90%;
      }
    }
  }
`;
